import { getAlignedTicks } from "@huxley-medical/react-components/utils"
import GridRows from "@visx/grid/lib/grids/GridRows"
import { ScaleLinear } from "d3-scale"
import { memo } from "react"

interface GridGraphRowProps {
  plotWidth: number
  lineHeight: number
  yScale: ScaleLinear<number, number, never>
}

const GridGraphRows = ({
  yScale,
  plotWidth,
  lineHeight,
}: GridGraphRowProps) => {
  const [yMin, yMax] = yScale.domain()
  return (
    <GridRows
      tickValues={getAlignedTicks({
        minTick: yMin,
        maxTick: yMax,
        numTicks: 4,
      })}
      scale={yScale}
      width={plotWidth}
      height={lineHeight}
      stroke="#E2E2E2"
    />
  )
}

export default memo(GridGraphRows, (_prevProps, _newProps) => {
  return (
    _prevProps.plotWidth === _newProps.plotWidth &&
    _prevProps.lineHeight === _newProps.lineHeight &&
    _prevProps.yScale.range().join() === _newProps.yScale.range().join() &&
    _prevProps.yScale.domain().join() === _newProps.yScale.domain().join()
  )
})
