import { LinePathPlot } from "@huxley-medical/react-components/components"
import { TimeSeriesPoint } from "@huxley-medical/react-components/types"
import { getX, getY } from "@huxley-medical/react-components/utils"
import { ScaleLinear } from "d3-scale"
import { memo } from "react"

interface GraphLinePathPlotProps {
  data: TimeSeriesPoint[]
  timeScale: ScaleLinear<number, number, never>
  yScale: ScaleLinear<number, number, never>
  plotWidth: number
  lineColor: string
}

const GraphLinePathPlot = ({
  data,
  timeScale,
  yScale,
  plotWidth,
  lineColor,
}: GraphLinePathPlotProps) => {
  return (
    <LinePathPlot
      data={data}
      x={(d: TimeSeriesPoint) => timeScale(getX(d)) ?? 0}
      y={(d: TimeSeriesPoint) => yScale(getY(d)) ?? 0}
      width={plotWidth}
      stroke={lineColor}
      strokeWidth={1.5}
      key={data.length}
      downsampleMethod="minMax"
    />
  )
}

export default memo(GraphLinePathPlot, (_prevProps, _newProps) => {
  return (
    _prevProps.plotWidth === _newProps.plotWidth &&
    _prevProps.lineColor === _newProps.lineColor &&
    _prevProps.timeScale.range().join() ===
      _newProps.timeScale.range().join() &&
    _prevProps.timeScale.domain().join() ===
      _newProps.timeScale.domain().join() &&
    _prevProps.yScale.range().join() === _newProps.yScale.range().join() &&
    _prevProps.yScale.domain().join() === _newProps.yScale.domain().join()
  )
})
