/* eslint-disable prettier/prettier */
import { atom, atomFamily, selector, selectorFamily } from "recoil"
import { EventApi, EventPlot, ScorerEventData, SelectedEvent } from "../types/event.type"
import { Metadata } from "../interfaces/study.interface"
import { filterPage } from "../utils/eventUtils"
import { activeCriteria } from "./study.state"

export const eventMetaData3 = atom<Metadata | undefined>({
  key: "eventMetaData3",
  default: undefined,
})

export const eventMetaData4 = atom<Metadata | undefined>({
  key: "eventMetaData4",
  default: undefined,
})

export const csdRegionsOfInterest3 = atom<EventApi[]>({
  key: "csdRegionsOfInterest3",
  default: [],
})

export const csdRegionsOfInterest4 = atom<EventApi[]>({
  key: "csdRegionsOfInterest4",
  default: [],
})

export const respiratoryEvents3 = atom<EventApi[]>({
  key: "respiratoryEvents3",
  default: [],
})

export const respiratoryEvents4 = atom<EventApi[]>({
  key: "respiratoryEvents4",
  default: [],
})

/**
 * eventContextMenu - holds SelectedEvent state for event context menu
 */
export const eventContextMenu = atom<SelectedEvent | undefined>({
  key: "eventContextMenu",
  default: undefined,
  // Fix error caused by storing mutating mouse event object in SelectedEvent
  dangerouslyAllowMutability: true,
})

/**
 * eventFamily - Memoized event family to fetch event data by eventIDs
 */
export const eventFamily = atomFamily<ScorerEventData, number>({
  key: "events",
})

/**
 * eventIDs - All events (just IDs) for a given sleep study
 */
export const eventIDs = atom<number[]>({
  key: "eventIDs",
  default: [],
})

/**
 * selectedEventID gets the current selected event ID for given study
 */
export const selectedEventID = selectorFamily({
  key: `selectedEventID`,
  get:
    ({ studyID }: { studyID: string | undefined }) =>
      ({ get }): number => {
        const selectedScoringCriteria = get(activeCriteria)
        const allIDs = get(eventIDs)
          .map((eventID) => get(eventFamily(eventID)))
          .filter((ev) => ev.studyID === studyID && ev.scoringCriteria === selectedScoringCriteria)

        return allIDs[allIDs.length - 1]?.id
      },
})

export const drawEventInProgressFamily = atomFamily<
  number[] | undefined,
  EventPlot
>({
  key: "drawEventInProgress",
  default: undefined,
})

export const sleepWakeEvents3 = atom<EventApi[]>({
  key: "sleepApiData3",
  default: [],
})

export const sleepWakeEvents4 = atom<EventApi[]>({
  key: "sleepApiData4",
  default: [],
})

export const nonRemovedEvents = selector<ScorerEventData[]>({
  key: "nonRemovedEvents",
  get: ({ get }) => {
    const allEvents = get(eventIDs)
      .map((eventID) => get(eventFamily(eventID)))
      .filter((eventData) => !eventData.removed)
    return allEvents
  },
})

export const activeDesatEvents = selector<ScorerEventData[]>({
  key: "desatEvents",
  get: ({ get }) => {
    const selectedScoringCriteria = get(activeCriteria)
    const allEvents = get(nonRemovedEvents)
    return allEvents.filter((eventData) => eventData.type === "Desat" && selectedScoringCriteria === eventData.scoringCriteria)
  },
})

export const nonSelectedCriteriaActiveDesatEvents = selector<ScorerEventData[]>({
  key: "nonSelectedCriteriaActiveDesatEvents ",
  get: ({ get }) => {
    const selectedScoringCriteria = get(activeCriteria)
    const allEvents = get(nonRemovedEvents)
    return allEvents.filter((eventData) => eventData.type === "Desat" && selectedScoringCriteria !== eventData.scoringCriteria)
  },
})

export const activeRespEvents = selector<ScorerEventData[]>({
  key: "respEvents",
  get: ({ get }) => {
    const selectedScoringCriteria = get(activeCriteria)
    const allEvents = get(nonRemovedEvents)
    return allEvents.filter((eventData) => eventData.type === "Resp" && selectedScoringCriteria === eventData.scoringCriteria)
  },
})

export const nonSelectedActiveRespEvents = selector<ScorerEventData[]>({
  key: "nonSelectedActiveRespEvents",
  get: ({ get }) => {
    const selectedScoringCriteria = get(activeCriteria)
    const allEvents = get(nonRemovedEvents)
    return allEvents.filter((eventData) => eventData.type === "Resp" && selectedScoringCriteria !== eventData.scoringCriteria)
  },
})

/**
 * plotTypeFilterEventsData - returns filtered list of EventData based
 * on provided plotType - no time domain filter
 */
export const plotTypeFilterEventsData = selectorFamily({
  key: `plotTypeFilterEventsData`,
  get:
    ({ plotType, studyID }: { plotType: EventPlot; studyID: string }) =>
      ({ get }) => {
      const selectedScoringCriteria = get(activeCriteria)
      return get(eventIDs)
        .map((eventID) => get(eventFamily(eventID)))
        .filter(
          (eventData) => eventData.plot === plotType && !eventData.removed && selectedScoringCriteria === eventData.scoringCriteria
        )
      },
  //.filter((eventData) => filterEventByStudy(eventData, studyID)),
})

/**
 * plotTypeTimeFilter - returns filtered list of eventIDs based on provided
 * plotType and timeScaleDomain
 */
export const plotTypeTimeFilter = selectorFamily({
  key: `plotTypeTimeFilterEvents`,
  get:
    ({
      plotType,
      timeScaleDomain,
      studyID,
    }: {
      plotType: EventPlot
      studyID: string
      timeScaleDomain: number[]
    }) =>
      ({ get }) => {
        const selectedCriteria = get(activeCriteria)
        return get(eventIDs)
          .map((eventID) => get(eventFamily(eventID)))
          .filter((eventData) => eventData.plot === plotType)
          .filter((eventData) => plotType === "ECG" || eventData?.scoringCriteria === selectedCriteria)
          //.filter((eventData) => filterEventByStudy(eventData, studyID))
          .filter(filterPage(timeScaleDomain))
          .map((eventData) => eventData.id)
      },
})

export const activeVebEvents = selector<ScorerEventData[]>({
  key: "vebEvents",
  get: ({ get }) => {
    const allEvents = get(nonRemovedEvents)
    return allEvents.filter((eventData) => eventData.label === "VEB")
  },
})

export const activeSvebEvents = selector<ScorerEventData[]>({
  key: "svebEvents",
  get: ({ get }) => {
    const allEvents = get(nonRemovedEvents)
    return allEvents.filter((eventData) => eventData.label === "SVEB")
  },
})