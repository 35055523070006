import { CallbackInterface } from "recoil"
import { ScorerEventData } from "../../../../types/event.type"
import { ScaleLinear } from "d3-scale"
import { TimeSeriesPoint } from "@huxley-medical/react-components/types"
import { binarySearchData, findMaxMinValues } from "../../../../utils/utils"
import { eventFamily } from "../../../../state/event.state"

/**
 * eventMoveResize is a recoil callback to handle moving or
 * resizing an event window
 *
 * @param callback
 * @returns callbackHandler
 */
export const eventMoveResize =
  (callback: CallbackInterface) =>
  (
    eventData: ScorerEventData,
    timeScale: ScaleLinear<number, number, never>,
    newRangePx: number[],
    data: TimeSeriesPoint[]
  ) => {
    const startingInterval = binarySearchData(
      data,
      timeScale.invert(newRangePx[0])
    )
    const endingInterval = binarySearchData(
      data,
      timeScale.invert(newRangePx[1])
    )

    const { max, min } = findMaxMinValues(
      data,
      startingInterval,
      endingInterval
    )

    callback.set(eventFamily(eventData.id), {
      // Keep other event values intact
      ...eventData,

      // set new range values
      event_ts: [
        // Convert the pixels to timescale
        timeScale.invert(newRangePx[0]),
        timeScale.invert(newRangePx[1]),
      ],
      event_data: {
        max_spo2: max,
        min_spo2: min,
      },
      autogenerated: false,
    })
  }
