import { LinePath } from "@visx/shape"
import { lttbDownsample } from "../../math/lttb"
import { minMaxDownsample } from "../../math/minMax"
import { TimeSeriesPoint } from "../../types"

const LinePathPlot = ({
  data,
  x,
  y,
  width,
  stroke,
  strokeWidth,
  downsampleMethod,
}: {
  data: TimeSeriesPoint[]
  x: (d: TimeSeriesPoint) => number
  y: (d: TimeSeriesPoint) => number
  width: number
  stroke: string
  strokeWidth: number
  downsampleMethod?: "lttb" | "none" | "minMax"
}): JSX.Element => {
  // check if data is greater than width
  // if so, downsample
  // if not, return data
  let downsampledData = data
  // get screen width

  let downsampledPoints: { x: number; y: number }[] = []

  if (
    downsampleMethod === "none" ||
    downsampleMethod === undefined ||
    data.length < width
  ) {
    downsampledData = data
  } else {
    // convert data to points
    const points = data.map((p) => ({ x: p.date.getTime(), y: p.value }))
    // downsample to max expected screen resolution of 8k
    if (downsampleMethod === "lttb" && data.length) {
      downsampledPoints = lttbDownsample(points, 2400)
    } else if (downsampleMethod === "minMax") {
      const bucketsize = Math.floor(data.length / width)
      downsampledPoints = minMaxDownsample(points, bucketsize)
    }

    // convert back to time series points
    downsampledData = downsampledPoints.map((p) => ({
      date: new Date(p.x),
      value: p.y,
    }))
  }

  return (
    <LinePath<TimeSeriesPoint>
      data={downsampledData}
      x={x}
      y={y}
      stroke={stroke}
      strokeWidth={strokeWidth}
      shapeRendering="geometricPrecision"
    />
  )
}

export default LinePathPlot
