import { TimeSeriesPoint } from "@huxley-medical/react-components/types"
import React, { memo } from "react"
import { ScaleLinear } from "d3-scale"
import { Text } from "@visx/text"
import { useRecoilValue } from "recoil"
import { intervalTimeEventTypeAtom } from "../../../state/study.state"
import { timeIntervalValues } from "../../../const/signals.const"
import { plotNumberStatusValues } from "../../../state/graph.state"
import { PlotNumberStatus } from "../../../interfaces/signals.interface"

interface LinePathPlotParams {
  title: string
  timeScale: ScaleLinear<number, number, never>
  yScale: ScaleLinear<number, number, never>
  textColor: string
  data: TimeSeriesPoint[]
}

const LinePathPlotNumbers = ({
  title,
  timeScale,
  yScale,
  textColor,
  data,
}: LinePathPlotParams) => {
  const [yMin, yMax] = yScale.domain()
  const yScaleRange = yMax - yMin
  const selectedTimeIntervalValue = useRecoilValue(intervalTimeEventTypeAtom)
  const plotNumberIntervalStatusData = useRecoilValue(
    plotNumberStatusValues(title as keyof PlotNumberStatus)
  )
  const intervalSeconds =
    plotNumberIntervalStatusData.intervals[selectedTimeIntervalValue]
  const maxValues: {
    [valueTimeStamp: string]: number
  } = {}
  const findPlotMaxValues = (data: TimeSeriesPoint[]) => {
    const intervalMilliseconds = intervalSeconds * 1000
    let startInterval = 0
    let intervalStart = data[0].date.getTime()
    let maxInInterval = data[0].value
    for (let i = 0; i < data.length; i++) {
      if (selectedTimeIntervalValue < 900000) {
        const currentTimestamp = data[i].date.getTime()
        if (currentTimestamp < intervalStart + intervalMilliseconds) {
          if (data[i].value > maxInInterval) {
            maxInInterval = data[i].value
          }
        } else {
          const valueTimeStamp = Math.floor((i - startInterval) / 2)
          const index = data[startInterval + valueTimeStamp].date.getTime()
          maxValues[index] = maxInInterval
          while (currentTimestamp >= intervalStart + intervalMilliseconds) {
            intervalStart += intervalMilliseconds
          }
          maxInInterval = data[i].value
          startInterval = i
        }
      }
    }
    const valueTimeStamp = Math.floor((data.length - 1 - startInterval) / 2)
    const index = data[startInterval + valueTimeStamp].date.getTime()
    maxValues[index] = maxInInterval
  }

  if (plotNumberIntervalStatusData.status) {
    findPlotMaxValues(data)
  } else {
    return <></>
  }
  return (
    <>
      {selectedTimeIntervalValue < timeIntervalValues.FIFTEEN_MINUTES &&
        data &&
        data.map((d, i) => (
          <React.Fragment key={i}>
            {d.date.getTime() in maxValues && (
              <>
                <Text
                  x={timeScale(new Date(d.date.getTime()))}
                  y={`${
                    yScale(maxValues[d.date.getTime()]) +
                    (yMax - maxValues[d.date.getTime()] > 0.5 * yScaleRange
                      ? -20
                      : 20)
                  }`}
                  fill={textColor}
                  fontSize={12}
                  textAnchor="middle"
                >
                  {maxValues[d.date.getTime()]}
                </Text>
              </>
            )}
          </React.Fragment>
        ))}
    </>
  )
}

export default memo(LinePathPlotNumbers, (_prevProps, _newProps) => {
  return (
    _prevProps.title === _newProps.title &&
    _prevProps.timeScale.range().join() ===
      _newProps.timeScale.range().join() &&
    _prevProps.timeScale.domain().join() ===
      _newProps.timeScale.domain().join() &&
    _prevProps.yScale.domain().join() === _newProps.yScale.domain().join() &&
    _prevProps.yScale.range().join() === _newProps.yScale.range().join() &&
    _prevProps.textColor === _newProps.textColor
  )
})
