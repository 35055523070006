import { getAlignedTicksInterval } from "@huxley-medical/react-components/utils"
import { Axis, Orientation, TickFormatter } from "@visx/axis"
import { NumberValue, ScaleLinear } from "d3-scale"
import { memo } from "react"

interface AxisGraphProps {
  top: number
  left: number
  hideTicks: boolean
  tickTransform: string
  scale: ScaleLinear<number, number, never>
  orientation: string | undefined
  minTick: number
  maxTick: number
  tickInterval: number
  tickFormat: TickFormatter<NumberValue>
}

type OrientationType = (typeof Orientation)[keyof typeof Orientation]

const AxisGraph = ({
  top,
  hideTicks,
  left,
  tickTransform,
  orientation,
  scale,
  minTick,
  maxTick,
  tickInterval,
  tickFormat,
}: AxisGraphProps) => {
  return (
    <Axis
      top={top}
      left={left}
      hideTicks={hideTicks}
      // Shift tick numbers to the left
      tickTransform={tickTransform}
      // Don't hide entire axis, this fixes a bottom plot line
      // zindex as side-effect
      tickValues={getAlignedTicksInterval({
        minTick,
        maxTick,
        tickInterval: tickInterval,
      })}
      tickFormat={tickFormat}
      orientation={orientation ? (orientation as OrientationType) : undefined}
      scale={scale}
    />
  )
}

export default memo(AxisGraph, (_prevProps, _newProps) => {
  return (
    _prevProps.top === _newProps.top &&
    _prevProps.maxTick === _newProps.maxTick &&
    _prevProps.minTick === _newProps.minTick &&
    _prevProps.tickInterval === _newProps.tickInterval
  )
})
