import { useRef, useState } from "react"
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil"
import { useWindowDrag } from "@huxley-medical/react-components/hooks/mouse"
import { Point, TimeSeriesPoint } from "@huxley-medical/react-components/types"
import { DynamicInterval } from "../../../utils/DynamicInterval"
import { relMouseCoords } from "@huxley-medical/react-components/utils"
import { useParams } from "react-router-dom"
import { eventIDs, plotTypeFilterEventsData } from "../../../state/event.state"
import { currentEpoch, numEpochs } from "../../../state/epoch.state"
import { snackAlert } from "../../../components/SnackAlerts"
import { handleEventResize } from "../../../components/scoring/event/handlers/handleEventResize"
import { eventMoveResize } from "../../../components/scoring/event/handlers/eventMoveResize"
import { eventMakeSelected } from "../../../components/scoring/event/handlers/eventMakeSelected"
import {
  nonRemovedEcgExclusions,
  nonRemovedPpgExclusions,
} from "../../../state/exclusions.state"
import { disableGraph } from "../../../state/graph.state"
import { ExclusionAnnotation } from "../../../types/exclusion.type"
import { useScrollRoutineScheduler } from "../../../components/scoring/scroll/hooks"
import { ScorerEventData } from "../../../types/event.type"
import { ScaleLinear } from "d3-scale"
import {
  ResizeScrollMode,
  ScrollRoutineContext,
} from "../../../types/scroller.type"
import { styled } from "@mui/joy/styles"
import { Bar } from "@visx/shape"

export const handleWidth = 15
export const minEventWidth = handleWidth

export const StyledEventHandle = styled(Bar)({
  fill: "transparent",
})

type HandleParams = {
  className?: string
  scrollMode: ResizeScrollMode | undefined
  setScrollMode: React.Dispatch<
    React.SetStateAction<ResizeScrollMode | undefined>
  >
  height: number
  x?: number
  range: number[]
  eventData: ScorerEventData
  timeScale: ScaleLinear<number, number, never>
  orientation: "left" | "right"
  parentWidth: number
  eventWidth: number
  isDragging?: (isDragging: boolean) => void
  xOffset: number
  plotData: TimeSeriesPoint[]
}

/**
 * EventHandle is a transparent component rendered on top of events on the left
 * or right side. It is used to resize the event window. On hover, it sets the
 * cursor to 'move' to designate interactivity.
 *
 * @param {HandleParams} handleParams
 * @returns {JSX.Element} JSX.Element
 */
const EventHandle = ({
  scrollMode,
  orientation,
  range,
  plotData,
  parentWidth,
  eventWidth,
  eventData,
  timeScale,
  xOffset,
  setScrollMode,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  isDragging = () => {},
  ...params
}: HandleParams): JSX.Element => {
  const [resizeEventStart, setResizeEventStart] = useState<undefined | Point>(
    undefined
  )

  const { studyID } = useParams()

  // Used to detect intersection with other events
  const allPlotEvents = useRecoilValue(
    plotTypeFilterEventsData({
      plotType: eventData.plot,
      studyID: studyID as string,
    })
  )

  // Used to compute relative mouse coords for event positioning
  const innerRef = useRef<SVGRectElement>(null)

  // Used for scrolling
  const scrollRoutine = useRef<DynamicInterval>(new DynamicInterval())
  const [epoch, setCurrentEpoch] = useRecoilState(currentEpoch)
  const totalEpochs = useRecoilValue(numEpochs)
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const handleEventMoveResize = useRecoilCallback(eventMoveResize)
  const handleEventMakeSelected = useRecoilCallback(eventMakeSelected)
  const events = useRecoilValue(eventIDs)
  const ecgExclusionsData = useRecoilValue(nonRemovedEcgExclusions)
  const ppgExclusionsData = useRecoilValue(nonRemovedPpgExclusions)
  const disabledGraph = useRecoilValue(disableGraph)
  const exclusionData: ExclusionAnnotation[] =
    eventData.plot === "HR" ? ecgExclusionsData : ppgExclusionsData

  // Used for refreshing moveEventStart after scroll
  const [moveEventStartRel, setMoveEventStartRel] = useState<undefined | Point>(
    undefined
  )

  // New px ranges must be converted to absolute time
  const setNewRange = (newRangePx: number[]) => {
    handleEventMoveResize(eventData, timeScale, newRangePx, plotData)
  }

  const handleWidthMove =
    eventWidth <= 5 ? Math.floor(eventWidth / 2) : handleWidth
  const [xMin, xMax] = timeScale.domain()
  const filteredPlotEvents = allPlotEvents.filter(
    (eventData: ScorerEventData) =>
      eventData.event_ts[1] > xMin && eventData.event_ts[0] < xMax
  )

  // Setup context for function run in setTimeout
  const scrollCtx: ScrollRoutineContext = {
    scrollMode,
    rangePx: range,
    currentEpoch: epoch,
    parentWidth,
    totalEpochs,
    exclusionData,
    timeScale,
    eventID: eventData.id,
    allPlotEvents,
    setSnackAlertMsg,
    setCurrentEpoch,
    setNewRange,
  }
  scrollRoutine.current.additional = scrollCtx

  // timer scheduler for scrolling epochs
  // activated by [scrollMode, moveEventStart]
  useScrollRoutineScheduler({
    scrollMode,
    moveEventStart: resizeEventStart,
    scrollRoutine,
    setScrollMode,
  })

  useWindowDrag(
    {
      start: resizeEventStart,
      el: innerRef.current,
      dragMove: handleEventResize({
        orientation,
        resizeEventStart,
        eventData,
        range,
        parentWidth,
        allPlotEvents: filteredPlotEvents,
        timeScale,
        scrollRoutine,
        xOffset,
        moveEventStartRel,
        setMoveEventStart: setResizeEventStart,
        setNewRange,
        setSnackAlertMsg,
        setScrollMode,
      }),
      dragEnd: () => {
        setResizeEventStart(undefined)
        isDragging(false)
      },
    },
    [filteredPlotEvents.length]
  )

  return (
    <StyledEventHandle
      {...params}
      sx={{
        cursor: disabledGraph ? "pointer" : "ew-resize",
        width: `${handleWidthMove}px`,
      }}
      innerRef={innerRef}
      onMouseDown={(e) => {
        // Prevent drawing new event on resize
        e.stopPropagation()
        if (disabledGraph) return

        setResizeEventStart({ x: e.pageX, y: e.pageY })
        // Used for refreshing event start after scroll
        setMoveEventStartRel(relMouseCoords(e, e.target as Element))
        isDragging(true)

        // stack element on top
        // which makes isSelected === true
        handleEventMakeSelected(eventData.id, events)
      }}
    />
  )
}

export default EventHandle
