/* eslint-disable prettier/prettier */
import { PositionMap } from "../const/signals.const"
import { BaseEvent } from "../interfaces/events.interface"
import { TimeSeriesPoint } from "@huxley-medical/react-components/types"
import { EventApi } from "../types/event.type"
import { Signal } from "../interfaces/signals.interface"

/**
 * Calculates the mean value of a time series.
 * @param points - An array of TimeSeriesPoint objects.
 * @param excludeZero - Optional. If true, excludes points with a value of zero from the calculation. Default is true.
 * @returns The mean value of the time series.
 */
export const timeseriesMean = (
  points: TimeSeriesPoint[],
  excludeZero = true
): number => {
  if (excludeZero) {
    points = points.filter((point) => point.value !== 0)
  }
  const sum = points.reduce((a, b) => a + b.value, 0)
  return sum / points.length
}

/**
 * Calculates the minimum value from an array of TimeSeriesPoint objects.
 *
 * @param points - The array of TimeSeriesPoint objects.
 * @param excludeZero - Optional. If true, excludes zero values from the calculation. Default is true.
 * @returns The minimum value from the array of TimeSeriesPoint objects.
 */
export const timeseriesMin = (
  points: TimeSeriesPoint[],
  excludeZero = true
): number => {
  if (excludeZero) {
    points = points.filter((point) => point.value !== 0)
  }
  return Math.min(...points.map((point) => point.value))
}

/**
 * Calculates the maximum value from an array of TimeSeriesPoint objects.
 *
 * @param points - An array of TimeSeriesPoint objects.
 * @param excludeZero - A boolean indicating whether to exclude zero values from the calculation. Default is true.
 * @returns The maximum value from the array of TimeSeriesPoint objects.
 */
export const timeseriesMax = (
  points: TimeSeriesPoint[],
  excludeZero = true
): number => {
  if (excludeZero) {
    points = points.filter((point) => point.value !== 0)
  }
  return Math.max(...points.map((point) => point.value))
}

export const timeSeriesFilterPointsToPointsConverter = ({
  signal,
  startTime,
  endTime,
}: {
  signal: Signal
  startTime: number
  endTime: number
}): TimeSeriesPoint[] => {
  if (signal.timestamps.length !== signal.values.length) {
    throw new Error("Timestamps and values length do not match")
  }
  if (
    startTime > signal.timestamps[signal.timestamps.length - 1] ||
    endTime < signal.timestamps[0]
  ) {
    return []
  }

  let startIndex = signal.timestamps.findIndex(
    (timestamp) => timestamp >= startTime
  )
  if (startIndex === -1) {
    startIndex = 0
  }

  //get index of last timestamp element matching end time
  let endIndex = signal.timestamps.findIndex((timestamp) => timestamp > endTime)
  if (endIndex === -1) {
    endIndex = signal.timestamps.length - 1
  }

  signal = {
    timestamps: signal.timestamps.slice(startIndex, endIndex === -1 ? signal.timestamps.length : endIndex + 1),
    values: signal.values.slice(startIndex, endIndex === -1 ? signal.timestamps.length : endIndex + 1),
  }

  return signal.values.map((valuesData: number, index: number) => {
    return {
      date: new Date(timeStampConverter(signal.timestamps[index])),
      value: valuesData === null || isNaN(valuesData) ? 0 : valuesData,
    }
  })
}

/**
 * Converts time series points to points.
 *
 * @param values - The array of values.
 * @param timestamps - The array of timestamps.
 * @returns An array of TimeSeriesPoint objects.
 */
export const timeSeriesPointsToPointsConvert = ({
  values,
  timestamps,
}: {
  values: number[]
  timestamps: number[]
}): TimeSeriesPoint[] =>
  values.map((valuesData: number, index: number) => {
    return {
      date: new Date(timeStampConverter(timestamps[index])),
      value: valuesData === null || isNaN(valuesData) ? 0 : valuesData,
    }
  })

/**
 * Converts time series points to points with a maximum and minimum value constraint.
 * @param timeseries - The time series data containing values and timestamps.
 * @param max - The maximum value constraint.
 * @param min - The minimum value constraint.
 * @returns An array of TimeSeriesPoint objects with values constrained between the maximum and minimum values.
 */
export const timeSeriesPointsToPointsConvertWithMaxMin = ({
  timeseries,
  max,
  min,
}: {
  timeseries: { values: number[]; timestamps: number[] }
  max: number
  min: number
}): TimeSeriesPoint[] => {
  const newVals = timeSeriesPointsToPointsConvert(timeseries)
  return newVals.map((point) => {
    return {
      ...point,
      value: point.value > max ? max : point.value < min ? min : point.value,
    }
  })
}

export function sleepWakeEventsToTimeSeriesPoints(
  events: EventApi[]
): TimeSeriesPoint[] {
  const points: TimeSeriesPoint[] = []
  const sleepWakeSamplingTime = timeStampConverter(0.5)
  const sleepWakeStartingSeries = timeStampConverter(120)
  const sleepWakeEndingSeries = timeStampConverter(600)

  //if events are empty, return empty array
  if (!events || events.length === 0) {
    return points
  }

  //pad the first 120 seconds with the wake label
  for (let i = 0; i < sleepWakeStartingSeries / sleepWakeSamplingTime; i++) {
    points.push({
      date: new Date(
        timeStampConverter(events[0].startTS) - sleepWakeStartingSeries + i * sleepWakeSamplingTime
      ),
      value: 0,
    })
  }

  events.forEach((event) => {
    const duration = timeStampConverter(event.endTS) - timeStampConverter(event.startTS) 

    const numSleepEpochs = Math.ceil(duration / sleepWakeSamplingTime) // create signal with sampling rate of

    //Last point of sleep and first point of next have same timestamp.
    //So we do not include the first point
    for (let i = 1; i < numSleepEpochs; i++) {
      points.push({
        date: new Date(timeStampConverter(event.startTS) + i * sleepWakeSamplingTime),
        value: event.event_data.sleep_label ? 1 : 0,
      })
    }
  })

  //add 120 of the last sleep label to the end
  for (let i = 1; i < sleepWakeEndingSeries / sleepWakeSamplingTime; i++) {
    points.push({
      date: new Date(
        timeStampConverter(events[events.length - 1].endTS) + i * sleepWakeSamplingTime
      ),
      value: events[events.length - 1].event_data.sleep_label ? 1 : 0,
    })
  }

  return points
}

export const bodyPosValuesToPostionMap = (value: number) =>
  ({
    6: PositionMap.Supine,
    1: PositionMap.Prone,
    12: PositionMap.Left,
    3: PositionMap.Right,
    0: PositionMap.Up,
    9: PositionMap["N/A"],
  }[value] || PositionMap["N/A"])

export const positionMapToBodyPositionValues = (key: number) =>
  ({
    [PositionMap.Supine]: 6,
    [PositionMap.Prone]: 1,
    [PositionMap.Left]: 12,
    [PositionMap.Right]: 3,
    [PositionMap.Up]: 0,
    [PositionMap["N/A"]]: 9,
  }[key] || 9)

/**
 * Gets the points overlapping not overlapping between a time series and an array of events.
 * @param timeSeries array of TimeSeriesPoint objects
 * @param events array of BaseEvent objects
 * @returns TimeSeriesPoint array of overlapping points
 */
export function getNotOverlappingTimeSeriesWithEventArray(
  timeSeries: TimeSeriesPoint[],
  events: BaseEvent[]
): TimeSeriesPoint[] {
  const nonOverlappingTimeSeries: TimeSeriesPoint[] = []
  timeSeries.forEach((point) => {
    let inEvent = false
    events.forEach((event) => {
      if (point.date.valueOf() >= timeStampConverter(event.startTS)) {
        if (point.date.valueOf() <= timeStampConverter(event.endTS)) {
          inEvent = true
        }
      }
    })
    if (!inEvent) {
      nonOverlappingTimeSeries.push(point)
    }
  })
  return nonOverlappingTimeSeries
}

/**
 * Gets the points overlapping between a time series and an array of events. (inclusive of start and end)
 * @param timeSeries array of TimeSeriesPoint objects
 * @param events array of BaseEvent objects
 * @returns TimeSeriesPoint array of overlapping points
 */
export function getOverlappingTimeSeriesWithEventArray(
  timeSeries: TimeSeriesPoint[],
  events: BaseEvent[]
): TimeSeriesPoint[] {
  const overlappingTimeSeries: TimeSeriesPoint[] = []
  timeSeries && timeSeries.forEach((point) => {
    events && events.forEach((event) => {
      if (point.date.valueOf() >= timeStampConverter(event.startTS)) {
        if (point.date.valueOf() <= timeStampConverter(event.endTS)) {
          overlappingTimeSeries.push(point)
          return //no need to continue through events if we already found one
        }
      }
    })
  })
  return overlappingTimeSeries
}

/**
 * Filters an array of TimeSeriesPoint objects based on a specified time range.
 * @param points - The array of TimeSeriesPoint objects to filter.
 * @param startTime - The start time of the range, in seconds.
 * @param endTime - The end time of the range, in seconds.
 * @returns An array of TimeSeriesPoint objects that fall within the specified time range.
 */
export const timeFilteredPoints = (
  points: TimeSeriesPoint[],
  startTime: number,
  endTime: number
): TimeSeriesPoint[] =>
  points.filter((point) => {
    const pointTime = point.date.valueOf()
    return pointTime >= timeStampConverter(startTime) && pointTime <= timeStampConverter(endTime)
  })

export const isNumberValue = (value: unknown): value is number => {
    return typeof value === 'number' && !isNaN(value)
}

export const timeStampConverter = (timeStamp: number) => timeStamp * 1000
