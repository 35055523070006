import { DragMoveCallback } from "@huxley-medical/react-components/hooks/mouse"
import { Point, TimeSeriesPoint } from "@huxley-medical/react-components/types"
import {
  ResizeScrollMode,
  ScrollRoutineContext,
} from "../../../../types/scroller.type"
import { eventIntersector } from "../../../../components/scoring/event/utils"
import { scrollError } from "../../../../components/scoring/scroll/hooks"
import { SnackAlert } from "../../../../types/snackAlert.types"
import { EventPlot, ScorerEventData } from "../../../../types/event.type"
import { DynamicInterval } from "../../../../utils/DynamicInterval"
import { DragThresholds } from "../../../../types/handlers.type"
import { SetterOrUpdater } from "recoil"
import { ScaleLinear } from "d3-scale"

export type HandleEventDragParams = {
  range: number[]
  parentWidth: number
  widthPx: number
  moveEventStart: Point | undefined
  eventID: number
  plot: EventPlot
  allPlotEvents: ScorerEventData[]
  data: TimeSeriesPoint[]
  timeScale: ScaleLinear<number, number, never>
  scrollRoutine: React.MutableRefObject<DynamicInterval>
  moveEventStartRel: Point | undefined
  xOffset: number
  dragThresholds: React.MutableRefObject<DragThresholds>
  setSnackAlertMsg: SetterOrUpdater<SnackAlert>
  setMoveEventStart: (value: React.SetStateAction<Point | undefined>) => void
  setNewRange: (newRangePx: number[]) => void
  setScrollMode: React.Dispatch<
    React.SetStateAction<ResizeScrollMode | undefined>
  >
}

export const moveError: SnackAlert = {
  open: true,
  message: "Unable to move, other event in the way",
  severity: "error",
}

/**
 * handleEventDrag is a callback to handle changing the
 * event's position on drag. Will activate scroll routine
 * via setScrollMode.
 *
 * @param {HandleEventDragParams} handleEventDragParams
 * @returns {DragMoveCallback} DragMoveCallback
 */

export const handleEventDrag = ({
  range,
  parentWidth,
  widthPx,
  moveEventStart,
  eventID,
  allPlotEvents,
  plot,
  data,
  timeScale,
  scrollRoutine,
  moveEventStartRel,
  xOffset,
  dragThresholds,
  setSnackAlertMsg,
  setMoveEventStart,
  setNewRange,
  setScrollMode,
}: HandleEventDragParams): DragMoveCallback => {
  return (_: Point, absPoint: Point) => {
    if (moveEventStart === undefined || plot === "ECG") return

    const ctx = scrollRoutine.current.additional as ScrollRoutineContext

    if (ctx.rangePx === undefined) {
      return
    }

    const changeFromStart = absPoint.x - moveEventStart.x
    const start = range[0] + changeFromStart

    const rangeEndUnmodified = range[0] + range[1]
    const rangeEnd = start + range[1]

    const eventIntersectionDetector = eventIntersector({
      eventID,
      widthPx,
      parentWidth,
      allPlotEvents,
      exclusions: ctx.exclusionData,
      timeScale,
    })

    // Set stateful vars for scroll epoch edge case
    // The user will drag, unless the start or end are visible
    // at some point during drag, and the predicted drag
    // (start or end) goes off screen.
    // Basically we drag when one or both of the event windows is offscreen
    if (rangeEnd < parentWidth) {
      dragThresholds.current.brokeRightThreshold = true
    }
    if (start > 0) {
      dragThresholds.current.brokeLeftThreshold = true
    }

    // trigger right scroll
    if (
      rangeEnd > parentWidth &&
      ((changeFromStart > 0 && rangeEndUnmodified <= parentWidth) ||
        dragThresholds.current.brokeRightThreshold)
    ) {
      dragThresholds.current.brokeLeftThreshold = false
      const endOfPlotStart = parentWidth - widthPx
      const endOfPlotEnd = parentWidth

      const intersect = eventIntersectionDetector(endOfPlotStart, endOfPlotEnd)

      // Don't activate scroll mode if the window positioned at end
      // of plot would intersect
      if (intersect.intersectedWith !== undefined) {
        setSnackAlertMsg(scrollError)
        return
      }

      // Trigger scroll routine in parent
      setScrollMode({
        method: "drag",
        orientation: "right",
        // times per second
        speed: Math.min(10, Math.max((rangeEnd - parentWidth) / 10, 1)),
      })

      const startPx = ctx.rangePx[0]

      // Ensure the event window is flush with end of plot before scroller startup
      if ((startPx + widthPx).toFixed(2) !== parentWidth.toFixed(2)) {
        setNewRange([endOfPlotStart, endOfPlotEnd])
      }

      return

      // Trigger left scroll routine in parent
    } else if (
      start < 0 &&
      ((changeFromStart < 0 && range[0] >= 0) ||
        dragThresholds.current.brokeLeftThreshold)
    ) {
      dragThresholds.current.brokeRightThreshold = false

      const startOfPlotStart = 0
      const startOfPlotEnd = widthPx

      const intersect = eventIntersectionDetector(
        startOfPlotStart,
        startOfPlotEnd
      )

      // Don't activate scroll mode if the window positioned at start
      // of plot would intersect
      if (intersect.intersectedWith !== undefined) {
        setSnackAlertMsg(scrollError)
        return
      }

      // Trigger state change in parent
      setScrollMode({
        method: "drag",
        orientation: "left",
        speed: Math.min(10, Math.max(Math.abs(start) / 10, 1)),
      })

      // Ensure the event window is flush with start of plot before scroller startup
      if (ctx.rangePx[0] !== startOfPlotStart)
        setNewRange([startOfPlotStart, startOfPlotEnd])

      return
    }

    if (scrollRoutine.current.started) {
      // Get scrollMode orientation so we know how to reset setMoveEventStart
      const ctx = scrollRoutine.current.additional as ScrollRoutineContext

      // X coord reletive to where the mouse clicked in event
      const moveStartRel = moveEventStartRel?.x as number

      // Right
      let newX = parentWidth - widthPx + moveStartRel + xOffset

      // Left
      if (ctx.scrollMode?.orientation === "left") {
        newX = xOffset + moveStartRel
      }

      // Trigger stop in useScrollRoutine
      setScrollMode(undefined)

      // Refresh useWindowDrag here
      setMoveEventStart({
        // end of plot, minus where the range was originally clicked
        x: newX,
        // x: absPoint.x,
        y: moveEventStart.y,
      })

      return
    }

    const end = start + widthPx

    // Don't allow event overlap
    const intersect = eventIntersectionDetector(start, end)

    // there was an overlap intersection or new location goes off
    // grid, do nothing
    if (intersect.suggestedRange === undefined) {
      setSnackAlertMsg(moveError)
      return
    }

    setNewRange([intersect.suggestedRange[0], intersect.suggestedRange[1]])
  }
}
