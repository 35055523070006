import { CallbackInterface } from "recoil"
import { eventIDs } from "../../../../state/event.state"

/**
 * eventMakeSelected is a recoil callback to handle making
 * an event window selected (last element of array).
 *
 * @param callback
 * @returns callbackHandler
 */
export const eventMakeSelected =
  (callback: CallbackInterface) => (eventID: number, events: number[]) => {
    // Move the changed event to end of event list
    // So it's always on top
    const findIndex = events.findIndex((eID) => eventID === eID)

    if (findIndex === undefined)
      throw new Error("unable to find event to move or resize")

    const updatedEvents = [...events]
    updatedEvents.splice(findIndex, 1)
    updatedEvents.push(eventID)

    callback.set(eventIDs, updatedEvents)
  }
