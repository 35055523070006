import { useRecoilCallback, useRecoilValue } from "recoil"
import useStudies from "./useStudies"
import {
  csdRegionsOfInterest3,
  csdRegionsOfInterest4,
  eventFamily,
  eventIDs,
  eventMetaData3,
  eventMetaData4,
  respiratoryEvents3,
  respiratoryEvents4,
  sleepWakeEvents3,
  sleepWakeEvents4,
} from "../state/event.state"
import {
  desatOdiAhiMetrics,
  summaryIndices3,
  summaryIndices4,
} from "../state/graphSummary.state"
import {
  ecgBadExclusions,
  ecgExclusions3,
  ecgExclusions4,
  ppgExclusions3,
  ppgExclusions4,
} from "../state/exclusions.state"
import { currentStudy } from "../state/study.state"
import { ScorerEventData, ScoringCriteria } from "../types/event.type"
import {
  saveBeatsEventDataConverter,
  saveEventDataConverter,
} from "../utils/eventUtils"
import { ExclusionAnnotation } from "../types/exclusion.type"
import { JSONContent } from "@tiptap/core"

const useInterpretStudy = () => {
  const studiesApi = useStudies()
  const study = useRecoilValue(currentStudy)
  const eventIdData = useRecoilValue(eventIDs)
  const eventMetaData_3 = useRecoilValue(eventMetaData3)
  const eventMetaData_4 = useRecoilValue(eventMetaData4)
  const eventSummaryIndices3 = useRecoilValue(summaryIndices3)
  const eventSummaryIndices4 = useRecoilValue(summaryIndices4)
  const csdRoI3 = useRecoilValue(csdRegionsOfInterest3)
  const csdRoI4 = useRecoilValue(csdRegionsOfInterest4)
  const desatOdiAhiMetricsData = useRecoilValue(desatOdiAhiMetrics)
  const ecgExclusionAnnotations3 = useRecoilValue(ecgExclusions3)
  const ecgExclusionAnnotations4 = useRecoilValue(ecgExclusions4)
  const ppgExclusionAnnotations3 = useRecoilValue(ppgExclusions3)
  const ppgExclusionAnnotations4 = useRecoilValue(ppgExclusions4)
  const respEvents3 = useRecoilValue(respiratoryEvents3)
  const respEvents4 = useRecoilValue(respiratoryEvents4)
  const sleep3 = useRecoilValue(sleepWakeEvents3)
  const sleep4 = useRecoilValue(sleepWakeEvents4)
  const fetchData = useRecoilCallback(
    ({ snapshot }) =>
      async (eventID: number) => {
        return await snapshot.getPromise(eventFamily(eventID))
      }
  )
  const ecgBadExclusionsData = useRecoilValue(ecgBadExclusions)

  interface InterpretStudyProps {
    interpretationEditorContentData: JSONContent
    setStudyInterpreted: React.Dispatch<React.SetStateAction<boolean>>
  }

  const interpretStudy = async ({
    interpretationEditorContentData,
    setStudyInterpreted,
  }: InterpretStudyProps) => {
    if (study?.uuid === undefined) return

    const eventPromiseData = await Promise.all(
      eventIdData.map((eventID) => fetchData(eventID))
    )
    const ahiEvents3 = eventPromiseData
      .filter(
        (event: ScorerEventData) =>
          event.plot === "HR" && event.scoringCriteria === ScoringCriteria.THREE
      )
      .map((event: ScorerEventData) => saveEventDataConverter(event))
    const ahiEvents4 = eventPromiseData
      .filter(
        (event: ScorerEventData) =>
          event.plot === "HR" && event.scoringCriteria === ScoringCriteria.FOUR
      )
      .map((event: ScorerEventData) => saveEventDataConverter(event))
    const desatEvents3 = eventPromiseData
      .filter(
        (event: ScorerEventData) =>
          event.plot === "SpO2" &&
          event.scoringCriteria === ScoringCriteria.THREE
      )
      .map((event: ScorerEventData) => saveEventDataConverter(event))
    const desatEvents4 = eventPromiseData
      .filter(
        (event: ScorerEventData) =>
          event.plot === "SpO2" &&
          event.scoringCriteria === ScoringCriteria.FOUR
      )
      .map((event: ScorerEventData) => saveEventDataConverter(event))
    const ecg_rhythms = [
      ...eventPromiseData
        .filter(
          (event: ScorerEventData) =>
            event.plot === "ECG" && event.type === "Rhythms"
        )
        .map((event: ScorerEventData) => saveEventDataConverter(event)),
      ...ecgBadExclusionsData
        .filter(
          (exclusionData) => exclusionData?.event_data?.label === "BAD_QUALITY"
        )
        .map((exclusionData: ExclusionAnnotation) => {
          delete exclusionData.label
          return exclusionData as unknown as ScorerEventData
        }),
    ]

    const lead_off_events = ecgBadExclusionsData
      .filter((exclusionData) => exclusionData.label === "leads_off_events")
      .map((exclusionData) => {
        delete exclusionData.label
        return exclusionData as unknown as ScorerEventData
      })

    const ecg_beats = eventPromiseData
      .filter(
        (event: ScorerEventData) =>
          event.plot === "ECG" && event.type === "Beats"
      )
      .map((event: ScorerEventData) => saveBeatsEventDataConverter(event))

    const { ahi3, ahi4, odi3, odi4 } = desatOdiAhiMetricsData
    const eventDataAhiThree = {
      metadata: eventMetaData_3,
      events: {
        csd_regions_of_interest: csdRoI3,
        desat_events: desatEvents3,
        ecg_exclusion_annotations: ecgExclusionAnnotations3,
        final_ahi_events: ahiEvents3,
        ppg_exclusion_annotations: ppgExclusionAnnotations3,
        respiratory_events: respEvents3,
        sleep: sleep3,
      },
      summary_indices: {
        ...eventSummaryIndices3,
        sahi_3: ahi3 && parseFloat(ahi3.toFixed(2)),
        sahi_4: ahi4 && parseFloat(ahi4.toFixed(2)),
        odi_3: odi3 && parseFloat(odi3.toFixed(2)),
        odi_4: odi4 && parseFloat(odi4.toFixed(2)),
      },
    }
    const eventDataAhiFour = {
      metadata: eventMetaData_4,
      events: {
        csd_regions_of_interest: csdRoI4,
        desat_events: desatEvents4,
        ecg_exclusion_annotations: ecgExclusionAnnotations4,
        final_ahi_events: ahiEvents4,
        ppg_exclusion_annotations: ppgExclusionAnnotations4,
        respiratory_events: respEvents4,
        sleep: sleep4,
      },
      summary_indices: {
        ...eventSummaryIndices4,
        sahi_3: ahi3 && parseFloat(ahi3.toFixed(2)),
        sahi_4: ahi4 && parseFloat(ahi4.toFixed(2)),
        odi_3: odi3 && parseFloat(odi3.toFixed(2)),
        odi_4: odi4 && parseFloat(odi4.toFixed(2)),
      },
    }
    await studiesApi.updateStudyEvents(study?.uuid, {
      eventDataAhiThree,
      eventDataAhiFour,
      ...(ecg_rhythms.length > 0 ||
      ecg_beats.length > 0 ||
      lead_off_events.length > 0
        ? {
            eventDataEcg: {
              rhythms: ecg_rhythms,
              beats: ecg_beats,
              leads_off_events: lead_off_events,
            },
          }
        : {}),
    })
    const success = await studiesApi.interpretStudy(study?.uuid, {
      interpretationNotes: { ...interpretationEditorContentData },
    })
    setStudyInterpreted(success)
  }

  return {
    interpretStudy,
  }
}

export default useInterpretStudy
