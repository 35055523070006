import { Point } from "../types"

export function minMaxDownsample(data: Point[], bucketSize: number): Point[] {
  const n = data.length

  if (n <= 2 || bucketSize >= n || bucketSize === 0) {
    // Not enough points to downsample
    return data
  }

  if (bucketSize <= 3) {
    // Bucketsize is too small
    console.warn("Bucketsize is too small returning data")
    return data
  }

  const sampledData: Point[] = []

  for (let i = 0; i < n; i += bucketSize) {
    // give some initial values which are at the beginning and end of the bucket
    let maxLoc = i
    let minLoc = i + bucketSize

    let max = -Infinity
    let min = Infinity

    for (let j = i; j < i + bucketSize; j++) {
      if (j >= n) {
        break
      }
      if (data[j].y > max) {
        max = data[j].y
        maxLoc = j
      }
      if (data[j].y < min) {
        min = data[j].y
        minLoc = j
      }
    }
    if (maxLoc > minLoc) {
      sampledData.push(data[minLoc])
      sampledData.push(data[maxLoc])
    } else {
      sampledData.push(data[maxLoc])
      sampledData.push(data[minLoc])
    }
  }

  return sampledData
}
