import AccessTimeIcon from "@mui/icons-material/AccessTime"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import Select from "@mui/joy/Select"
import Stack from "@mui/joy/Stack"
import Typography from "@mui/joy/Typography"
import Option from "@mui/joy/Option"
import {
  intervalTimeEventTypeAtom,
  timeIntervals,
} from "../../../state/study.state"
import { isLoadingStudy } from "../../../state/study.state"
import { timeIntervalType } from "../../../types/time.type"

/**
 * IntervalFilter is a functional component for selecting intervals of
 * 30 seconds, 2 minutes, 5 minutes, 10 minutes, 15 minutes, 30 minutes,
 * 1 hour, and 2 hours.
 *
 * @returns {JSX.Element} JSX.Element
 */
const IntervalFilter = (): JSX.Element => {
  const [intervalTime, setInternvalTime] = useRecoilState(
    intervalTimeEventTypeAtom
  )
  const timeIntervalsList = useRecoilValue(timeIntervals)
  const setIsLoadingStudy = useSetRecoilState(isLoadingStudy)

  const selectedGraphInterval = (
    event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    if (newValue === null) {
      return
    }
    setIsLoadingStudy(true)
    setInternvalTime(parseInt(newValue, 10))
    setIsLoadingStudy(false)
  }

  return (
    <Select
      variant="soft"
      startDecorator={
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <AccessTimeIcon />
          <Typography level="title-sm">Interval:</Typography>
        </Stack>
      }
      value={intervalTime.toString()}
      onChange={selectedGraphInterval}
      size="md"
    >
      {timeIntervalsList.map((interval: timeIntervalType, index: number) => {
        return (
          <Option key={index} value={interval.value.toString()}>
            {interval.label.toString()}
          </Option>
        )
      })}
    </Select>
  )
}

export default IntervalFilter
