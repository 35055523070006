import { useRecoilTransaction_UNSTABLE, useSetRecoilState } from "recoil"
import { usePortalApi } from "../connections"
import { snackAlert } from "../components/SnackAlerts"
import { useEffect } from "react"
import {
  activeCriteria,
  currentStudy,
  fullNightInterval,
  intervalTimeEventTypeAtom,
  isLoadingStudy,
  studyMetaData3,
  studyMetaData4,
  timeIntervals,
} from "../state/study.state"
import {
  ecgBadExclusions,
  ecgExclusions3,
  ecgExclusions4,
  ppgExclusions3,
  ppgExclusions4,
} from "../state/exclusions.state"
import {
  csdRegionsOfInterest3,
  csdRegionsOfInterest4,
  eventFamily,
  eventIDs,
  eventMetaData3,
  eventMetaData4,
  respiratoryEvents3,
  respiratoryEvents4,
  sleepWakeEvents3,
  sleepWakeEvents4,
} from "../state/event.state"
import { currentEpoch } from "../state/epoch.state"
import { summaryIndices3, summaryIndices4 } from "../state/graphSummary.state"
import { allPlotTimeDomain, studySignals } from "../state/signals.state"
import {
  EventApi,
  EventPlot,
  EventType,
  ScorerEventData,
  ScoringCriteria,
} from "../types/event.type"
import { EventsData } from "../interfaces/events.interface"
import { SignalsData } from "../interfaces/signals.interface"
import { handleApiError } from "../utils/apiUtils"
import {
  eventApiData,
  eventApiDataConverter,
  leadOffToExclusion,
} from "../utils/eventUtils"
import { graphTimeIntervals } from "../const/signals.const"
import { ExclusionAnnotation } from "../types/exclusion.type"
import { reportStatus } from "../state/pdfReport.state"
import { SleepStudyOutData } from "../types/study.type"
import {
  bodyPosValuesToPostionMap,
  sleepWakeEventsToTimeSeriesPoints,
  timeSeriesFilterPointsToPointsConverter,
} from "../utils/seriesUtils"
import { FEATURE_ECG_ANALYSIS } from "../features"
import useResetRecoilStateData from "./useResetRecoilStateData"
import { fetchApiData, fetchEventData } from "../utils/utils"

const useLoadStudy = (studyID: string) => {
  const api = usePortalApi()
  const { resetGraphData } = useResetRecoilStateData()
  const setIsLoadingStudy = useSetRecoilState(isLoadingStudy)
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const setReportStatus = useSetRecoilState(reportStatus)
  const createStudyData = useRecoilTransaction_UNSTABLE(
    ({ set, reset }) =>
      async (
        signalData: SignalsData,
        eventData3: EventsData,
        eventData4: EventsData,
        study: SleepStudyOutData | undefined,
        allEventData: ScorerEventData[]
      ) => {
        const startTime = eventData3.metadata.study_start * 1000
        const endTime = eventData3.metadata.study_end * 1000
        const allNightIntervalTime = endTime - startTime
        set(currentStudy, study)
        set(
          activeCriteria,
          study && study?.studyOrder.oxygenDesatThreshold === "three"
            ? ScoringCriteria.THREE
            : ScoringCriteria.FOUR
        )
        set(eventMetaData3, eventData3.metadata)
        set(eventMetaData4, eventData4.metadata)
        set(summaryIndices3, eventData3.summary_indices)
        set(summaryIndices4, eventData4.summary_indices)
        set(csdRegionsOfInterest3, eventData3.events.csd_regions_of_interest)
        set(csdRegionsOfInterest4, eventData4.events.csd_regions_of_interest)
        set(studyMetaData3, {
          friendlyId: studyID,
          studyStartTime: eventData3.metadata.study_start,
          studyEndTime: eventData3.metadata.study_end,
          scoringCriteria: ScoringCriteria.THREE,
          swVersion: eventData3.metadata.autoscore_version ?? undefined,
        })
        set(studyMetaData4, {
          friendlyId: studyID,
          studyStartTime: eventData4.metadata.study_start,
          studyEndTime: eventData4.metadata.study_end,
          scoringCriteria: ScoringCriteria.FOUR,
          swVersion: eventData3.metadata.autoscore_version ?? undefined,
        })
        set(studySignals, {
          respiratory_effort: signalData.resp
            ? timeSeriesFilterPointsToPointsConverter({
                signal: signalData.resp,
                startTime: eventData3.metadata.study_start,
                endTime: eventData3.metadata.study_end,
              })
            : [],
          snore: signalData.snore_envelop
            ? timeSeriesFilterPointsToPointsConverter({
                signal: signalData.snore_envelop,
                startTime: eventData3.metadata.study_start,
                endTime: eventData3.metadata.study_end,
              })
            : [],
          spo2: signalData.spo2
            ? timeSeriesFilterPointsToPointsConverter({
                signal: signalData.spo2,
                startTime: eventData3.metadata.study_start,
                endTime: eventData3.metadata.study_end,
              })
            : [],
          hr: signalData.hr
            ? timeSeriesFilterPointsToPointsConverter({
                signal: signalData.hr,
                startTime: eventData3.metadata.study_start,
                endTime: eventData3.metadata.study_end,
              })
            : [],
          actigraphy: signalData.actigraphy
            ? timeSeriesFilterPointsToPointsConverter({
                signal: signalData.actigraphy,
                startTime: eventData3.metadata.study_start,
                endTime: eventData3.metadata.study_end,
              })
            : [],
          chest_movement: signalData.chest_movement
            ? timeSeriesFilterPointsToPointsConverter({
                signal: signalData.chest_movement,
                startTime: eventData3.metadata.study_start,
                endTime: eventData3.metadata.study_end,
              })
            : [],
          body_position: signalData.body_position
            ? timeSeriesFilterPointsToPointsConverter({
                signal: {
                  values: signalData.body_position.values.map((value: number) =>
                    bodyPosValuesToPostionMap(value)
                  ),
                  timestamps: signalData.body_position.timestamps,
                },
                startTime: eventData3.metadata.study_start,
                endTime: eventData3.metadata.study_end,
              })
            : [],
          sleep: sleepWakeEventsToTimeSeriesPoints(eventData3.events.sleep),
          ecg: signalData.ecg
            ? timeSeriesFilterPointsToPointsConverter({
                signal: signalData.ecg,
                startTime: eventData3.metadata.study_start,
                endTime: eventData3.metadata.study_end,
              })
            : [],
        })
        set(timeIntervals, [
          { label: "All Night", value: allNightIntervalTime },
          ...graphTimeIntervals,
        ])
        set(fullNightInterval, allNightIntervalTime)
        set(intervalTimeEventTypeAtom, allNightIntervalTime)
        set(allPlotTimeDomain, [
          eventData3.metadata.study_start * 1000,
          eventData3.metadata.study_end * 1000,
        ])
        set(respiratoryEvents3, eventData3.events.respiratory_events)
        set(respiratoryEvents4, eventData4.events.respiratory_events)
        set(sleepWakeEvents3, eventData3.events.sleep)
        set(sleepWakeEvents4, eventData3.events.sleep)
        reset(eventIDs)
        set(ecgExclusions3, eventData3.events.ecg_exclusion_annotations ?? [])
        set(ecgExclusions4, eventData4.events.ecg_exclusion_annotations ?? [])
        set(ppgExclusions3, eventData3.events.ppg_exclusion_annotations ?? [])
        set(ppgExclusions4, eventData4.events.ppg_exclusion_annotations ?? [])
        set(ecgBadExclusions, eventData3.events.ecg_bad_annotations ?? [])
        set(currentEpoch, 0)
        const allEventId: number[] = []
        allEventData.forEach(async (eventData: ScorerEventData) => {
          allEventId.push(eventData.id)
          set(eventFamily(eventData.id), eventData)
        })
        set(eventIDs, allEventId)
      }
  )

  const fetchStudy = async (
    studyid: string
  ): Promise<SleepStudyOutData | undefined> => {
    if (!api) return
    try {
      const study = await api.getStudy({ studyId: studyid })
      return study as SleepStudyOutData
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  useEffect(() => {
    resetGraphData()
    setIsLoadingStudy(true)
    if (!api) return

    const fetchData = async () => {
      try {
        const study = await fetchStudy(studyID)
        if (!study) {
          throw new Error("Failed to fetch study data")
        }
        const urlresponse = await api.getStudyUrl({
          studyId: studyID,
        })
        if (!urlresponse) {
          throw new Error("Failed to fetch study data")
        }
        const { ahi3Url, ahi4Url, signalsUrl, pulseAnnotationsUrl } =
          urlresponse
        const signalData = await fetchApiData(signalsUrl)
        const eventData3 = (await fetchEventData(ahi3Url)) as EventsData
        const eventData4 = (await fetchEventData(ahi4Url)) as EventsData

        if (!eventData3 && !eventData4) {
          throw new Error("Study has no event data")
        }
        // These values aren't extracted nor edited; save them to include in the interpret study request input
        const respiratory_events = [
          ...(eventData3.events.final_ahi_events
            ? eventApiDataConverter(
                eventData3.events.final_ahi_events,
                "HR" as EventPlot,
                "Resp" as EventType,
                studyID,
                ScoringCriteria.THREE
              )
            : []),
          ...(eventData4.events.final_ahi_events
            ? eventApiDataConverter(
                eventData4.events.final_ahi_events,
                "HR" as EventPlot,
                "Resp" as EventType,
                studyID,
                ScoringCriteria.FOUR
              )
            : []),
        ]
        const desat_events = [
          ...(eventData3.events.desat_events
            ? eventApiDataConverter(
                eventData3.events.desat_events,
                "SpO2" as EventPlot,
                "Desat" as EventType,
                studyID,
                ScoringCriteria.THREE
              )
            : []),
          ...(eventData4.events.desat_events
            ? eventApiDataConverter(
                eventData4.events.desat_events,
                "SpO2" as EventPlot,
                "Desat" as EventType,
                studyID,
                ScoringCriteria.FOUR
              )
            : []),
        ]
        if (!pulseAnnotationsUrl || !FEATURE_ECG_ANALYSIS) {
          await createStudyData(signalData, eventData3, eventData4, study, [
            ...respiratory_events,
            ...desat_events,
          ])
          setIsLoadingStudy(false)
          return
        }

        const ecgEventConverter = (
          ecgEvent: EventApi[],
          eventPlot: EventPlot,
          eventType: EventType,
          studyID: string,
          ecgCondition: string[]
        ) => {
          const ecgEventData: ScorerEventData[] = []
          for (let i = 0; i < ecgEvent.length; i++) {
            const event = ecgEvent[i]
            if (!ecgCondition.includes(event.event_data.label)) {
              ecgEventData.push(
                eventApiData(event, eventPlot, eventType, studyID)
              )
            }
            if (
              eventType === "Rhythms" &&
              event.event_data.label === "BAD_QUALITY"
            ) {
              ecg_bad_exclusions.push(event as unknown as ExclusionAnnotation)
            }
          }
          return ecgEventData
        }
        const ecgAnn = await fetchApiData(pulseAnnotationsUrl as string)
        if (ecgAnn && ecgAnn.heart_rate) {
          signalData.hr = {
            timestamps: ecgAnn.heart_rate.timestamps,
            values: ecgAnn.heart_rate.values,
          }
        }
        const ecg_bad_exclusions: ExclusionAnnotation[] = []
        const rhythmCondition = ["Normal Sinus Rhythm", "NSR", "BAD_QUALITY"]
        const beatsCondition = ["N"]
        const ecg_rhythms: ScorerEventData[] | [] = ecgAnn.rhythms
          ? ecgEventConverter(
              ecgAnn.rhythms,
              "ECG" as EventPlot,
              "Rhythms" as EventType,
              studyID,
              rhythmCondition
            )
          : []
        const ecg_beats: ScorerEventData[] | [] = ecgAnn.beats
          ? ecgEventConverter(
              ecgAnn.beats,
              "ECG" as EventPlot,
              "Beats" as EventType,
              studyID,
              beatsCondition
            )
          : []
        if (ecgAnn.leads_off_events) {
          ecg_bad_exclusions.push(
            ...leadOffToExclusion(ecgAnn.leads_off_events)
          )
        }

        eventData3.events.ecg_bad_annotations = ecg_bad_exclusions

        if (
          ecgAnn?.rhythms?.length > 0 ||
          ecgAnn?.beats?.length > 0 ||
          ecgAnn?.leads_off_events?.length > 0
        ) {
          setReportStatus(true)
        }
        const allEventData = [
          ...respiratory_events,
          ...desat_events,
          ...ecg_rhythms,
          ...ecg_beats,
        ]
        await createStudyData(
          signalData,
          eventData3,
          eventData4,
          study,
          allEventData
        )
        setIsLoadingStudy(false)
      } catch (error) {
        handleApiError(setSnackAlertMsg)(error)
      }
    }
    fetchData()
  }, [api])
}

export default useLoadStudy
