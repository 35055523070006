import InternalHeader from "../../components/internalheader/InternalHeader"
import { Box, Button } from "@mui/joy"
import { useRecoilState, useRecoilValue } from "recoil"
import { currentStudy } from "../../state/study.state"
import { currentUser } from "../../state/auth.state"
import { StudyStatus, statusStrToEnum } from "../../utils/studyUtils"
import useGenerateReportPdf from "../../hooks/useGenerateReportPdf"
import {
  isGeneratingPDFReport,
  reportStatus,
} from "../../state/pdfReport.state"
import { FEATURE_ECG_ANALYSIS } from "../../features"
import { Download } from "@mui/icons-material"
import useStudies from "../../hooks/useStudies"

const StudyScoringHeader = ({
  studyID,
  openInterpretationDrawer,
}: {
  studyID: string | undefined
  openInterpretationDrawer: () => void
}) => {
  const { generatePdf } = useGenerateReportPdf()
  const studies = useStudies()
  const downloadSleepReport = () => {
    studies.downloadSleepStudy({ studyId: studyID || "" })
  }
  const [isGeneratingPDF, setIsGeneratingPdf] = useRecoilState(
    isGeneratingPDFReport
  )
  const reportButtonStatus = useRecoilValue(reportStatus)
  const study = useRecoilValue(currentStudy)
  const user = useRecoilValue(currentUser)
  const printEcgPdf = async () => {
    setIsGeneratingPdf(true)
    await generatePdf({ studyId: studyID as string })
    return
  }

  if (study === undefined && isGeneratingPDF === false) return null
  const headerList = [
    {
      fontWeight: 500,
      fontSize: 12,
      text: "Patient: " + study !== undefined ? study?.patient?.name : "N/A",
    },
    {
      fontWeight: 500,
      fontSize: 12,
      text: "Studies",
    },
    {
      fontWeight: 500,
      fontSize: 12,
      text: study?.id === undefined ? "N/A" : study.id.toString(),
    },
  ]

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <InternalHeader breadCrumbList={headerList}>
        <></>
      </InternalHeader>
      <Box sx={{ display: "flex", gap: "0.5rem" }}>
        {user?.primaryRole === "Physician" && (
          <Button
            disabled={
              study !== undefined
                ? statusStrToEnum[study.status] === StudyStatus.COMPLETE
                : false
            }
            variant="solid"
            size="md"
            onClick={openInterpretationDrawer}
          >
            Interpret
          </Button>
        )}
        {study !== undefined && (
          <Button
            variant="solid"
            size="md"
            onClick={downloadSleepReport}
            disabled={statusStrToEnum[study.status] !== StudyStatus.COMPLETE}
          >
            <Download sx={{ mr: 1 }} />
            Report
          </Button>
        )}
        {FEATURE_ECG_ANALYSIS && (
          <Button
            disabled={!reportButtonStatus}
            variant="solid"
            size="md"
            onClick={printEcgPdf}
          >
            ECG Report
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default StudyScoringHeader
