import { PlotNumberStatus } from "../interfaces/signals.interface"
import { TimeIntervalValuesType, timeIntervalType } from "../types/time.type"

export const PositionMap = {
  Up: 1,
  Prone: 5,
  Right: 3,
  Supine: 2,
  "N/A": 0,
  Left: 4,
}

export const SleepStageMap = {
  Wake: 0,
  Sleep: 1,
}

export const timeIntervalValues: TimeIntervalValuesType = {
  TEN_SECONDS: 10000, // 10 seconds interval
  THIRTY_SECONDS: 30000, // 30 seconds interval
  ONE_AND_A_HALF_MINUTES: 90000, //  1.5 minutes interval
  THREE_MINUTES: 180000, // 3 minutes interval
  FIVE_MINUTES: 300000, // 5 minutes interval
  TEN_MINUTES: 600000, // 10 minutes interval
  FIFTEEN_MINUTES: 900000, // 15 minutes interval
  THIRTY_MINUTES: 1800000, // 30 minutes interval
  ONE_HOUR: 3600000, // 1 hour interval
  TWO_HOURS: 7200000, // 2 hours interval
}

export const graphTimeIntervals: timeIntervalType[] = [
  { label: "10 Sec", value: timeIntervalValues.TEN_SECONDS },
  { label: "30 Sec", value: timeIntervalValues.THIRTY_SECONDS },
  { label: "1.5 Mins", value: timeIntervalValues.ONE_AND_A_HALF_MINUTES },
  { label: "3 Mins", value: timeIntervalValues.THREE_MINUTES },
  { label: "5 Mins", value: timeIntervalValues.FIVE_MINUTES },
  { label: "10 Mins", value: timeIntervalValues.TEN_MINUTES },
  { label: "15 Mins", value: timeIntervalValues.FIFTEEN_MINUTES },
  { label: "30 Mins", value: timeIntervalValues.THIRTY_MINUTES },
  { label: "1 hr", value: timeIntervalValues.ONE_HOUR },
  { label: "2 hrs", value: timeIntervalValues.TWO_HOURS },
]

const plotNumberIntervals = {
  [timeIntervalValues.THIRTY_SECONDS]: 15, // 15 seconds for 30 seconds interval
  [timeIntervalValues.ONE_AND_A_HALF_MINUTES]: 15, // 15 seconds for 1.5 mins interval
  [timeIntervalValues.THREE_MINUTES]: 15, // 15 seconds for  3 minutes interval
  [timeIntervalValues.FIVE_MINUTES]: 15, // 15 seconds for  5 minutes interval
  [timeIntervalValues.TEN_MINUTES]: 30, // 30 seconds for  10 minutes interval
}

export const defaultPlotNumberIntervalStatusValues: PlotNumberStatus = {
  SpO2: {
    status: false,
    intervals: plotNumberIntervals,
  },
  HR: {
    status: false,
    intervals: plotNumberIntervals,
  },
  ECG: {
    status: false,
    intervals: plotNumberIntervals,
  },
  Snoring: {
    status: false,
    intervals: plotNumberIntervals,
  },
  "Resp. Effort": {
    status: false,
    intervals: plotNumberIntervals,
  },
  Chest: {
    status: false,
    intervals: plotNumberIntervals,
  },
  Actigraphy: {
    status: false,
    intervals: plotNumberIntervals,
  },
}
