import { atom, selector } from "recoil"
import { TimeSeriesPoint } from "@huxley-medical/react-components/types"
import { activeCriteria, studyMetaData3 } from "../state/study.state"
import {
  allNonRemovedExclusions,
  nonExcludedHr,
  nonSelectedCriteriaAllExclusions,
} from "../state/exclusions.state"
import { SleepInfo, StudyPlotSignals } from "../interfaces/signals.interface"
import {
  getNotOverlappingTimeSeriesWithEventArray,
  getOverlappingTimeSeriesWithEventArray,
  sleepWakeEventsToTimeSeriesPoints,
} from "../utils/seriesUtils"
import { getSumOfOverlappingTimeBetweenTwoEventArrays } from "../utils/eventUtils"
import { EventApi, ScoringCriteria } from "../types/event.type"
import { sleepWakeEvents3, sleepWakeEvents4 } from "./event.state"

export const sleepPlot = selector<TimeSeriesPoint[]>({
  key: "sleepPlot",
  get: ({ get }) => {
    const selectedScoringCriteria = get(activeCriteria)
    const sleepE = get(
      ScoringCriteria.THREE === selectedScoringCriteria
        ? sleepWakeEvents3
        : sleepWakeEvents4
    )

    return sleepWakeEventsToTimeSeriesPoints(sleepE)
  },
})

export const studySignals = atom<StudyPlotSignals>({
  key: "studySignals",
  default: undefined,
})

export const nonExcludedSpo2 = selector<TimeSeriesPoint[]>({
  key: "nonExcludedSpo2",
  get: ({ get }) => {
    const studySignalData = get(studySignals)
    const exclusions = get(allNonRemovedExclusions)
    return getNotOverlappingTimeSeriesWithEventArray(
      studySignalData && studySignalData.spo2 ? get(studySignals).spo2 : [],
      exclusions
    )
  },
})

export const sleepingHr = selector<TimeSeriesPoint[]>({
  key: "sleepingHr",
  get: ({ get }) => {
    const selectedScoringCriteria = get(activeCriteria)
    const hr = get(nonExcludedHr)
    const sleepwakeEvents = get(
      ScoringCriteria.THREE === selectedScoringCriteria
        ? sleepWakeEvents3
        : sleepWakeEvents4
    )
    const sleepE =
      sleepwakeEvents &&
      sleepwakeEvents.filter((event) => event.event_data.sleep_label)
    return getOverlappingTimeSeriesWithEventArray(hr, sleepE)
  },
})

export const allPlotTimeDomain = atom<number[]>({
  key: "allPlotTimeDomain",
  default: [0, 0],
})

/**
 * sleepInfo - returns the sleepInfo for given study
 */
export const sleepInfo = selector<SleepInfo>({
  key: "sleepInfo",
  get: ({ get }) => {
    const selectedScoringCriteria = get(activeCriteria)
    const sleepE = get(
      ScoringCriteria.THREE === selectedScoringCriteria
        ? sleepWakeEvents3
        : sleepWakeEvents4
    )
    const exclusions = get(allNonRemovedExclusions)
    const studyStart = get(studyMetaData3).studyStartTime
    const studyEnd = get(studyMetaData3).studyEndTime
    const sleepDurationSeconds =
      sleepE &&
      sleepE
        .filter((sleepData: EventApi) => sleepData.event_data.sleep_label)
        .reduce((acc, event) => acc + event.endTS - event.startTS, 0)

    const sleepTime =
      sleepE &&
      sleepE.filter((sleepData: EventApi) => {
        return sleepData.event_data.sleep_label
      })

    const exclusionsExceptForLowAmp = exclusions.filter(
      (exclusion) => exclusion.event_data?.label !== 2
    )

    const efftst =
      sleepDurationSeconds -
      (getSumOfOverlappingTimeBetweenTwoEventArrays(
        sleepTime,
        exclusionsExceptForLowAmp
      ) +
        0)

    const firstSleepAwake =
      sleepE &&
      sleepE.find((sleepData: EventApi) => sleepData.event_data.sleep_label)
    const sleepLatencySeconds = firstSleepAwake
      ? firstSleepAwake.startTS - studyStart
      : 0

    const sleepEfficiencyPercent =
      (sleepDurationSeconds / (studyEnd - studyStart)) * 100

    return {
      sleepDurationSeconds,
      sleepLatencySeconds,
      sleepEfficiencyPercent,
      effSleepTime: efftst,
    }
  },
})

/**
 * sleepInfo - returns the sleepInfo for given study
 */
export const nonSelectedScoringCriteriaSleepInfo = selector<SleepInfo>({
  key: "nonSelectedScoringCriteriaSleepInfo",
  get: ({ get }) => {
    const selectedScoringCriteria = get(activeCriteria)
    const sleepE = get(
      ScoringCriteria.THREE === selectedScoringCriteria
        ? sleepWakeEvents4
        : sleepWakeEvents3
    )
    const exclusions = get(nonSelectedCriteriaAllExclusions)
    const studyStart = get(studyMetaData3).studyStartTime
    const studyEnd = get(studyMetaData3).studyEndTime
    const sleepDurationSeconds =
      sleepE &&
      sleepE
        .filter((sleepData: EventApi) => sleepData.event_data.sleep_label)
        .reduce((acc, event) => acc + event.endTS - event.startTS, 0)

    const sleepTime =
      sleepE &&
      sleepE.filter((sleepData: EventApi) => {
        return sleepData.event_data.sleep_label
      })

    const exclusionsExceptForLowAmp = exclusions.filter(
      (exclusion) => exclusion.event_data?.label !== 2
    )

    const efftst =
      sleepDurationSeconds -
      (getSumOfOverlappingTimeBetweenTwoEventArrays(
        sleepTime,
        exclusionsExceptForLowAmp
      ) +
        0)

    const firstSleepAwake =
      sleepE &&
      sleepE.find((sleepData: EventApi) => sleepData.event_data.sleep_label)
    const sleepLatencySeconds = firstSleepAwake
      ? firstSleepAwake.startTS - studyStart
      : 0

    const sleepEfficiencyPercent =
      (sleepDurationSeconds / (studyEnd - studyStart)) * 100

    return {
      sleepDurationSeconds,
      sleepLatencySeconds,
      sleepEfficiencyPercent,
      effSleepTime: efftst,
    }
  },
})
