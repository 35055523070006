import { Point } from "@huxley-medical/react-components/types"
import { DragMoveCallback } from "@huxley-medical/react-components/hooks/mouse"
import {
  ResizeScrollMode,
  ScrollRoutineContext,
} from "../../../../types/scroller.type"
import {
  eventIntersector,
  findEventIntersectEnd,
  findEventIntersectStart,
} from "../../../../components/scoring/event/utils"
import { scrollError } from "../../../../components/scoring/scroll/hooks"
import {
  handleWidth,
  minEventWidth,
} from "../../../../components/scoring/event/EventHandle"
import { ScorerEventData } from "../../../../types/event.type"
import { DynamicInterval } from "../../../../utils/DynamicInterval"
import { SetterOrUpdater } from "recoil"
import { SnackAlert } from "../../../../types/snackAlert.types"
import { ScaleLinear } from "d3-scale"

/**
 * handleEventResize is a callback for handling resizing an event window. It
 * prevents windows from being resized outside of visible plot area, or on top
 * of other events.
 *
 * @param {HandleEventResizeParams} handleEventResizeParams
 * @returns {DragMoveCallback} DragMoveCallback
 */

export type HandleEventResizeParams = {
  orientation: "left" | "right"
  resizeEventStart: Point | undefined
  range: number[]
  parentWidth: number
  allPlotEvents: ScorerEventData[]
  timeScale: ScaleLinear<number, number, never>
  eventData?: ScorerEventData
  scrollRoutine: React.MutableRefObject<DynamicInterval>
  xOffset: number
  moveEventStartRel: Point | undefined
  setMoveEventStart: (value: React.SetStateAction<Point | undefined>) => void
  setSnackAlertMsg: SetterOrUpdater<SnackAlert>
  setNewRange: (newRangePx: number[]) => void
  setScrollMode: (
    value: React.SetStateAction<ResizeScrollMode | undefined>
  ) => void
}

export const handleEventResize =
  ({
    orientation,
    resizeEventStart,
    range,
    parentWidth,
    allPlotEvents,
    timeScale,
    eventData,
    scrollRoutine,
    xOffset,
    moveEventStartRel,
    setMoveEventStart,
    setNewRange,
    setScrollMode,
    setSnackAlertMsg,
  }: HandleEventResizeParams): DragMoveCallback =>
  (_: Point, absPoint: Point) => {
    if (resizeEventStart === undefined) return

    const ctx = scrollRoutine.current.additional as ScrollRoutineContext
    if (ctx.rangePx === undefined) return

    const changeFromStart = absPoint.x - resizeEventStart.x

    const eventIntersectionDetector = eventIntersector({
      eventID: eventData?.id,
      widthPx: ctx.rangePx[1],
      exclusions: ctx.exclusionData,
      parentWidth,
      allPlotEvents: ctx.allPlotEvents,
      timeScale: ctx.timeScale,
    })

    if (orientation === "right") {
      const rangeData = range
      let width = range[1] + changeFromStart

      //prevent from reducing to less than 2 seconds
      const mindist = Math.abs(timeScale(2000) - timeScale(0))
      // Prevent inverting selection
      if (width <= mindist) {
        width = mindist
      }

      const rangeEnd = rangeData[0] + width

      // If the range end is greater than parent width
      if (rangeEnd > parentWidth) {
        const endOfWindowPx = ctx.rangePx[0] + ctx.rangePx[1] + changeFromStart

        const intersect = eventIntersectionDetector(
          ctx.rangePx[0],
          endOfWindowPx
        )

        // Don't activate scroll mode if the window positioned at end
        // of plot would intersect
        if (intersect.intersectedWith !== undefined) {
          setSnackAlertMsg(scrollError)
          return
        }

        setScrollMode({
          method: "resize",
          orientation: "right",
          // times per second
          speed: Math.min(10, Math.max((rangeEnd - parentWidth) / 10, 1)),
        })

        // Ensure the event window is flush with end of plot before scroller startup
        if (
          (ctx.rangePx[0] + ctx.rangePx[1]).toFixed(2) !==
          parentWidth.toFixed(2)
        ) {
          setNewRange([ctx.rangePx[0], parentWidth])
        }

        return
      }

      if (scrollRoutine.current.started) {
        // X coord reletive to where the mouse clicked in event
        const moveStartRel = moveEventStartRel?.x as number

        // Right
        const newX = parentWidth - handleWidth + moveStartRel + xOffset

        // Trigger stop in useScrollRoutine
        setScrollMode(undefined)

        // Refresh useWindowDrag here
        setMoveEventStart({
          // end of plot, minus where the range was originally clicked
          x: newX,
          // x: absPoint.x,
          y: resizeEventStart.y,
        })

        return
      }

      let end = rangeData[0] + width

      // Prevent event from exceding any start
      const intersectEventStart = findEventIntersectStart(
        allPlotEvents,
        ctx.exclusionData,
        timeScale,
        eventData?.id,
        rangeData[0],
        end
      )

      if (intersectEventStart !== undefined) {
        end = intersectEventStart
      }

      setNewRange([rangeData[0], end])
    } else if (orientation === "left") {
      // Extend width for new left start
      let width =
        range[1] +
        (changeFromStart < 0 ? Math.abs(changeFromStart) : changeFromStart * -1)

      // Prevent left dragging moving start
      if (width <= minEventWidth) {
        return
      }

      // Prevent inverting selection
      //prevent from reducing to less than 2 seconds
      const mindist = Math.abs(timeScale(2000) - timeScale(0))

      if (width <= mindist) {
        width = mindist
      }

      let start = range[0] + changeFromStart

      // Prevent left overflow
      if (start < 0) {
        const endOfWindowPx = ctx.rangePx[0] + ctx.rangePx[1]

        const intersect = eventIntersectionDetector(
          ctx.rangePx[0] + changeFromStart,
          endOfWindowPx
        )

        // Don't activate scroll mode if the window positioned at end
        // of plot would intersect
        if (intersect.intersectedWith !== undefined) {
          setSnackAlertMsg(scrollError)
          return
        }

        setScrollMode({
          method: "resize",
          orientation: "left",
          // times per second
          speed: Math.min(10, Math.max(Math.abs(start) / 10, 1)),
        })

        // Ensure the event window is flush with end of plot before scroller startup
        if (ctx.rangePx[0].toFixed(2) !== (0).toFixed(2)) {
          setNewRange([0, endOfWindowPx])
        }

        return
      }

      if (scrollRoutine.current.started) {
        // X coord reletive to where the mouse clicked in event
        const moveStartRel = moveEventStartRel?.x as number

        // Right
        const newX = xOffset + moveStartRel

        // Trigger stop in useScrollRoutine
        setScrollMode(undefined)

        // Refresh useWindowDrag here
        setMoveEventStart({
          // end of plot, minus where the range was originally clicked
          x: newX,
          // x: absPoint.x,
          y: resizeEventStart.y,
        })

        return
      }

      const end = start + width

      // Prevent event start from being less than any end
      const intersectEventEnd = findEventIntersectEnd(
        allPlotEvents,
        ctx.exclusionData,
        timeScale,
        eventData?.id,
        start,
        end
      )

      if (intersectEventEnd !== undefined) {
        start = intersectEventEnd
      }

      setNewRange([start, end])
    }
  }
