import React, { useMemo } from "react"
import { Group } from "@visx/group"
import { CallbackInterface, RecoilState, useRecoilValue } from "recoil"
import {
  ecgBadExclusions,
  ecgExclusions3,
  ecgExclusions4,
  ppgExclusions3,
  ppgExclusions4,
} from "../../../state/exclusions.state"
import { EventPlot, ScoringCriteria } from "../../../types/event.type"
import { ExclusionAnnotation } from "../../../types/exclusion.type"
import Exclusion from "./Exclusion"
import { ScaleLinear } from "d3-scale"
import { activeCriteria } from "../../../state/study.state"
import { ExclusionData } from "../../../interfaces/exclusions.interface"
export type EventPlotExclusions = "HR" | "SpO2" | "ECG"
export const exclusionRemove =
  (callback: CallbackInterface) =>
  (
    exclusionID: number,
    plot: EventPlot,
    exclusionPlottingData: ExclusionAnnotation[],
    userId: string | undefined,
    selectedScoringCriteria: ScoringCriteria
  ) =>
  () => {
    const exclusionData: ExclusionAnnotation[] = [...exclusionPlottingData]
    exclusionData[exclusionID] = {
      ...exclusionData[exclusionID],
      removed: true,
      removedOn: new Date().getTime() / 1000,
      removedBy: userId,
    }

    const isScoring3 = ScoringCriteria.THREE === selectedScoringCriteria

    const exclusionMapping: Record<
      EventPlotExclusions,
      RecoilState<ExclusionData[]>
    > = {
      HR: isScoring3 ? ecgExclusions3 : ecgExclusions4,
      SpO2: isScoring3 ? ppgExclusions3 : ppgExclusions4,
      ECG: ecgBadExclusions,
    }

    if (plot === "HR" || plot === "SpO2" || plot === "ECG") {
      callback.set(exclusionMapping[plot], exclusionData)
    }
  }

type PlotExclusionParams = {
  height: number
  width: number
  children?: React.ReactNode
  timeScale: ScaleLinear<number, number, never>
  plot: EventPlot
}

const PlotExclusions = ({
  height,
  children,
  timeScale,
  plot,
  width,
}: PlotExclusionParams): JSX.Element => {
  const ecgExclusionsData3 = useRecoilValue(ecgExclusions3)
  const ecgExclusionsData4 = useRecoilValue(ecgExclusions4)
  const ppgExclusionsData3 = useRecoilValue(ppgExclusions3)
  const ppgExclusionsData4 = useRecoilValue(ppgExclusions4)
  const selectedScoringCriteria = useRecoilValue(activeCriteria)
  const ecgExclusionsData =
    selectedScoringCriteria === ScoringCriteria.THREE
      ? ecgExclusionsData3
      : ecgExclusionsData4
  const ppgExclusionsData =
    selectedScoringCriteria === ScoringCriteria.THREE
      ? ppgExclusionsData3
      : ppgExclusionsData4
  const ecgBadExclusionsData = useRecoilValue(ecgBadExclusions)
  const exclusionForPlotting = useMemo(() => {
    if (plot === "HR") {
      return ecgExclusionsData
    }
    if (plot === "SpO2") {
      return ppgExclusionsData
    }
    if (plot === "ECG") {
      return ecgBadExclusionsData
    }
    return []
  }, [plot, ecgExclusionsData, ppgExclusionsData, ecgBadExclusionsData])
  return (
    <Group className="plot-events">
      {children /* grid rows, cols, and line path */}
      {["SpO2", "HR", "ECG"].includes(plot) &&
        exclusionForPlotting.length > 0 &&
        exclusionForPlotting.map((exclusion, index) =>
          !exclusion.removed ? (
            <Exclusion
              key={index}
              exclusionIndex={index}
              height={height}
              plot={plot}
              timeScale={timeScale}
              startTs={exclusion.startTS}
              endTs={exclusion.endTS}
              exclusionData={exclusion}
              exclusionForPlotting={exclusionForPlotting}
            />
          ) : (
            <React.Fragment key={index} />
          )
        )}
    </Group>
  )
}

export default PlotExclusions
