/* eslint-disable prettier/prettier */
import { BaseEvent } from "../interfaces/events.interface"
import { DetectionFlags, EventApi, EventPlot, EventType, ScorerEventData, ScoringCriteria } from "../types/event.type"
import { Signal } from "../interfaces/signals.interface"
import { beatsDefaultLabel, beatsLabel, rhythmsDefaultLabel, rhythmsLabel } from "../const/event.const"
import { ExclusionAnnotation } from "../types/exclusion.type"

export function sumDesatEventsBetweenValues(
  desatEvents: ScorerEventData[],
  min: number,
  max: number
): number {
  return desatEvents.filter((eventData) => {
    const maxSpo2 = eventData.event_data?.max_spo2
    const minSpo2 = eventData.event_data?.min_spo2
    return (
      maxSpo2 != null &&
      minSpo2 != null &&
      maxSpo2 - minSpo2 >= min &&
      maxSpo2 - minSpo2 < max
    )
  }).length
}

export function meanOfDesatNadirs(desatEvents: ScorerEventData[]): number {
  const nadirValues = desatEvents
    .map((eventData) => {
      return eventData.event_data?.min_spo2 || NaN
    })
    .filter((value) => !isNaN(value))
  return nadirValues.reduce((a, b) => a + b, 0) / nadirValues.length
}

/**
 * filterPage - returns a filter function for EventData[] based
 * on the given x-domain
 * @param timeScaleDomain
 * @returns {(eventData: ScorerEventData) => boolean}
 */
export const filterPage =
  (timeScaleDomain: number[]): ((eventData: ScorerEventData) => boolean) =>
    (eventData: ScorerEventData) => {
      return (
        (eventData.event_ts[0] >= timeScaleDomain[0] &&
          eventData.event_ts[0] <= timeScaleDomain[1]) ||
        (eventData.event_ts[1] >= timeScaleDomain[0] &&
          eventData.event_ts[1] <= timeScaleDomain[1]) ||
        (eventData.event_ts[0] <= timeScaleDomain[0] &&
          eventData.event_ts[1] >= timeScaleDomain[1])
      )
    }

/**
 * Calculates the overlapping time between two events.
 * @param event1 - The first event.
 * @param event2 - The second event.
 * @returns The duration of the overlapping time in milliseconds.
 */
export function overlappingTimeBetweenEvents(
  event1: BaseEvent,
  event2: BaseEvent
): number {
  const [start1, end1] = [event1.startTS, event1.endTS]
  const [start2, end2] = [event2.startTS, event2.endTS]
  const start = Math.max(start1, start2)
  const end = Math.min(end1, end2)
  return Math.max(0, end - start)
}

/**
 * Calculates the sum of overlapping time between two arrays of events.
 * @param events1 The first array of events.
 * @param events2 The second array of events.
 * @returns The sum of overlapping time between the two arrays of events.
 */
export function getSumOfOverlappingTimeBetweenTwoEventArrays(
  events1: BaseEvent[],
  events2: BaseEvent[]
): number {
  let sum = 0
  events1 && events1.forEach((event1) => {
    events2 && events2.forEach((event2) => {
      if (event1.startTS > event2.endTS || event1.endTS < event2.startTS) {
        return
      }
      sum += overlappingTimeBetweenEvents(event1, event2)
    })
  })
  return sum
}

export const saveEventDataConverter = (eventData: ScorerEventData) => {
  return {
    uuid: eventData.id,
    startTS: eventData.event_ts[0] / 1000,
    endTS: eventData.event_ts[1] / 1000,
    autogenerated: eventData?.autogenerated,
    addedBy: eventData.addedBy,
    addedOn: eventData.addedOn,
    removed: eventData.removed,
    removedBy: eventData.removedBy,
    removedOn: eventData.removedOn,
    ...(eventData.plot === "SpO2" && {
      event_data: {
        max_spo2: eventData.event_data?.max_spo2,
        min_spo2: eventData.event_data?.min_spo2,
      },
    }),
    ...(eventData.plot === "ECG" && eventData.type === "Rhythms" && {
      event_data: {
        label: rhythmsDefaultLabel[eventData.label as string],
      },
    }),
  }
}

export const saveBeatsEventDataConverter = (eventData: ScorerEventData) => {
  return {
    uuid: eventData.id,
    ts: eventData.event_ts[0] / 1000,
    autogenerated: eventData?.autogenerated,
    addedBy: eventData.addedBy,
    addedOn: eventData.addedOn,
    removed: eventData.removed,
    removedBy: eventData.removedBy,
    removedOn: eventData.removedOn,
    event_data: {
      label: beatsDefaultLabel[eventData.label as string],
    },
  }
}

export const eventApiData = (
  eventData: EventApi,
  plotType: EventPlot,
  eventType: EventType,
  studyID: string,
  scoringCriteria?: ScoringCriteria
  ) => {
    return {
      id: eventData?.uuid ? eventData.uuid : (eventType === "Beats" ? eventData.ts : Number(eventData.startTS.toFixed(0))) + (eventType === "Rhythms" ? eventData.endTS.toFixed(0) : 0),
      plot: plotType,
      studyID,
      type: eventType,
      event_ts: [(eventType === "Beats" ? eventData.ts * 1000 : eventData.startTS * 1000), (eventType === "Beats" ? eventData.ts * 1000 : eventData.endTS * 1000)],
      event_data: {
        max_spo2: Number(eventData?.event_data?.max_spo2) || null,
        min_spo2: Number(eventData?.event_data?.min_spo2) || null,
      },
      autogenerated: eventData?.autogenerated,
      addedBy: eventData?.addedBy,
      addedOn: eventData?.addedOn,
      removed: eventData?.removed,
      removedBy: eventData?.removedBy,
      removedOn: eventData?.removedOn,
      ...(plotType === "ECG" ? { label: eventType === "Rhythms" ? rhythmsLabel[eventData.event_data.label] : beatsLabel[eventData.event_data.label] }: {}),
      ...(scoringCriteria !== undefined ? {scoringCriteria} : {})
    }
}

/**
 * Converts event data to scorer event data format.
 *
 * @param eventData - The array of events to be converted.
 * @param plotType - The type of plot for the events.
 * @param eventType - The type of event.
 * @param studyID - The ID of the study.
 * @returns The converted scorer event data array.
 */
export const eventApiDataConverter = (
  eventData: EventApi[],
  plotType: EventPlot,
  eventType: EventType,
  studyID: string,
  scoringCriteria?: ScoringCriteria
): ScorerEventData[] => {
  return eventData.map((event: EventApi) => eventApiData(event, plotType, eventType, studyID, scoringCriteria))
}

/**
 * Filters a signal based on a specified time range. Inclusive of start and end
 * @param signal - The signal to be filtered.
 * @param startTime - The start time of the filter range.
 * @param endTime - The end time of the filter range.
 * @returns The filtered signal within the specified time range.
 */
export const timeFilteredSignal = (
  signal: Signal,
  startTime: number,
  endTime: number
): Signal => {
  //check that length of signal timestamps and values are the same
  if (signal.timestamps.length !== signal.values.length) {
    throw new Error("Timestamps and values length do not match")
  }

  if (
    startTime > signal.timestamps[signal.timestamps.length - 1] ||
    endTime < signal.timestamps[0]
  ) {
    return {
      timestamps: [],
      values: [],
    }
  }

  //get index of first timestamp element matching start time
  let startIndex = signal.timestamps.findIndex(
    (timestamp) => timestamp >= startTime
  )
  if (startIndex === -1) {
    startIndex = 0
  }

  //get index of last timestamp element matching end time
  let endIndex = signal.timestamps.findIndex((timestamp) => timestamp > endTime)
  if (endIndex === -1) {
    endIndex = signal.timestamps.length - 1
  }

  return {
    timestamps: signal.timestamps.slice(startIndex, endIndex),
    values: signal.values.slice(startIndex, endIndex),
  }
}

// Function to convert an integer to a flag
export function intToSpo2Flag(value: number): DetectionFlags | null {
  for (const flag in DetectionFlags) {
    if (!isNaN(Number(flag))) {
      const flagValue = Number(flag)
      if ((value & flagValue) === flagValue) {
        return flagValue as DetectionFlags
      }
    }
  }
  return null // Return null if no matching flag is found
}

export const leadOffToExclusion = (ecgEventData: EventApi[]) => {
  return ecgEventData.map((data: EventApi) => {
    return { ...data, label: "leads_off_events" }
  }) as unknown as ExclusionAnnotation[]
}
