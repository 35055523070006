import { CallbackInterface } from "recoil"
import { EventType, ScorerEventData } from "../../../../types/event.type"
import { eventFamily } from "../../../../state/event.state"

/**
 * eventTypeSelection
 * event type.
 *
 * @param callback
 * @returns callbackHandler
 */
export const eventTypeSelection =
  (callback: CallbackInterface) =>
  (eventData: ScorerEventData) =>
  (updatedType: EventType) =>
    callback.set(eventFamily(eventData.id), {
      ...eventData,
      type: updatedType,
    })
