import { Refresh, Search } from "@mui/icons-material"
import {
  Box,
  Button,
  Chip,
  Dropdown,
  Option,
  FormControl,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  Select,
  Sheet,
  Stack,
  Switch,
  ListDivider,
  Typography,
} from "@mui/joy"
import {
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid"
import * as React from "react"
import { useEffect, useState } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"
import NewStudyModal from "./newstudymodal"
import { formatDate } from "../../utils/formatDate"
import { createBasicGridColDef } from "../../utils/tableUtils"
import { removeEmptyValuesFromObject } from "../../utils/utils"
import { getQueryFromUrl } from "../../utils/urlUtils"
import {
  SimplifiedStudyStatus,
  StatusTextToStr,
  StudyStatus,
  getStudyStatusColorAndText,
  simplifyStudyStatus,
  statusStrToEnum,
  studyStatusValues,
} from "../../utils/studyUtils"
import MoreVert from "@mui/icons-material/MoreVert"
import DeleteStudyModal from "./DeleteStudyModal"
import FulfillStudyModal from "./FulfillStudyModal"
import StudyUploadModal from "./StudyUploadModal"
import useStudies from "../../hooks/useStudies"
import EditStudyModal from "./EditStudyModal"
import { emptySelectedPatient } from "./newstudymodal/NewStudyModal"
import { emptyStudyInput } from "./newstudymodal/StudyDetailForm"
import { currentUser, isHuxleyUser } from "../../state/auth.state"
import { disableGraph } from "../../state/graph.state"
import { PatientIdentifier } from "../../types/patient.type"
import { StyledDataGrid } from "../../styled/DataGrid"
import {
  deleteStudyModalOpen,
  fulfillStudyModalOpen,
  studyToBeDeleted,
  studyToBeFulfilled,
  studyToBeUploaded,
  studyUploadModalOpen,
} from "../../state/modal.state"
import { GetAllStudyOrdersRequest, UserOut } from "../../generated/fetchclient"
import useOrganizations from "../../hooks/useOrganizations"

interface StudyTableProps {
  showAdminOptions?: boolean
}
export interface StudyTableRow {
  id?: string
  readableId: number
  name: string
  mrn: string
  dob: Date
  referringPhysician: string
  orderingPhysician: string
  date: Date
  interpretingPhysician: string
  scoringCriteria: string
  // sleepTime: string  TODO: implement later from JSON events
  device: string
  status: StudyStatus
  patientId: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
  scheduledDate: Date
  oxygenDesatThreshold: string
  orderingPhysicianId: string
  interpretingPhysicianId: string
  assignedDevice: string
  notes: string
  type: string
  modalStatus: string
}

const oxygenDesatThresholdToScoringCriteria: { [key: string]: string } = {
  three: "3%",
  four: "4%",
  both: "3% and 4%",
}

export function StudyStatusIndicator(status: StudyStatus) {
  const repr = getStudyStatusColorAndText(status)
  return (
    <Chip
      color={repr.color}
      sx={{
        "--Chip-radius": "4px",
      }}
      variant="outlined"
    >
      {repr.text}
    </Chip>
  )
}

const defaultPropValues: StudyTableProps = {
  showAdminOptions: true,
}

const emptyStudyQuery = (user?: UserOut): GetAllStudyOrdersRequest => {
  return {
    patientName: "",
    mrn: "",
    studyStatuses: [],
    referringPhysicianName: "",
    interpretingPhysicianName: "",
    interpretingPhysicianId:
      user?.primaryRole === "Physician" ? user?.uuid : "",
  }
}

const canAnalyzeStudyStatuses = [
  StudyStatus.COMPLETE,
  StudyStatus.READY_FOR_INTERPRETATION,
  StudyStatus.READY_FOR_REVIEW,
]

function StudyTable(props: StudyTableProps = defaultPropValues) {
  const { organizations, listOrganizations } = useOrganizations()
  const isAdmin = useRecoilValue(isHuxleyUser)

  const [tableRows, setTableRows] = useState<StudyTableRow[]>(
    [] as StudyTableRow[]
  )
  const studies = useStudies()
  const { studies: apiStudies, studyCount } = studies
  const [isLoading, setIsLoading] = useState(false)
  const user = useRecoilValue(currentUser)
  const isUserPhysician = user?.primaryRole === "Physician"

  const setDeleteModalOpenState = useSetRecoilState(deleteStudyModalOpen)
  const setStudyToBeDeleted = useSetRecoilState(studyToBeDeleted)
  const setFulfillModalOpenState = useSetRecoilState(fulfillStudyModalOpen)
  const setStudyToBeFulfilled = useSetRecoilState(studyToBeFulfilled)
  const setStudyToBeUploaded = useSetRecoilState(studyToBeUploaded)
  const setOpenUploadModal = useSetRecoilState(studyUploadModalOpen)
  const setDisableGraph = useSetRecoilState(disableGraph)
  const nav = useNavigate()
  const [, setSearchParams] = useSearchParams()
  const [open, setOpen] = useState(false)
  const searchParamsQuery: GetAllStudyOrdersRequest = getQueryFromUrl()

  const [statusFilter, setStatusFilter] = useState<string[]>(
    searchParamsQuery["studyStatuses"]?.map(
      (status: string) => statusStrToEnum[status]
    ) ?? []
  )
  const [selfInterpretingPhysician, setSelfInterpretingPhysician] = useState(
    user || Object.keys(searchParamsQuery).length > 0
      ? isUserPhysician ||
          Object.keys(searchParamsQuery).includes("interpretingPhysicianId")
      : true
  )
  const [selectedPatient, setSelectedPatient] =
    useState<PatientIdentifier>(emptySelectedPatient)
  const [editModalData, setEditModalData] = useState(emptyStudyInput)
  const studyStatusValue = React.useMemo(() => studyStatusValues(), [])
  const [studyQuery, setStudyQuery] =
    useState<GetAllStudyOrdersRequest>(searchParamsQuery)

  useEffect(() => {
    const rows = apiStudies.map((s) => {
      const status = statusStrToEnum[s.status]
      return {
        id: s.uuid,
        readableId: s.id,
        name: s.patient.name,
        mrn: s.patient.mrn,
        dob: s.patient.dob,
        referringPhysician: s.patient.referringPhysician,
        orderingPhysician: s.orderingPhysician.name,
        date: s.scheduledDate,
        interpretingPhysician: s.interpretingPhysician.name,
        scoringCriteria:
          oxygenDesatThresholdToScoringCriteria[s.oxygenDesatThreshold],
        device: `SANSA ${s.type}`,
        status: props.showAdminOptions ? status : simplifyStudyStatus(status),
        activeStudyId: s.activeStudyId ?? "",
        organization: s.organization,
        patientId: s.patient.uuid ?? "",
        address1: s.address1,
        address2: s.address2 ?? "",
        city: s.city,
        state: s.state,
        zip: s.zip,
        scheduledDate: s.scheduledDate,
        oxygenDesatThreshold: s.oxygenDesatThreshold,
        orderingPhysicianId: s.orderingPhysician.uuid,
        interpretingPhysicianId: s.interpretingPhysician.uuid,
        assignedDevice: s.assignedDevice,
        notes: s.notes ?? "",
        type: s.type,
        modalStatus: s.status,
      }
    })

    setTableRows(rows)
  }, [apiStudies])

  useEffect(() => {
    if (isAdmin) listOrganizations()
  }, [isAdmin])

  // Rerender when selfInterpretingPhysician changes
  useEffect(() => {
    return
  }, [selfInterpretingPhysician])
  useEffect(() => {
    const queryParams = {
      patientName: studyQuery.patientName ?? "",
      mrn: studyQuery.mrn ?? "",
      interpretingPhysicianName: studyQuery.interpretingPhysicianName ?? "",
      referringPhysicianName: studyQuery.referringPhysicianName ?? "",
      studyStatuses: studyQuery.studyStatuses ?? "",
      interpretingPhysicianId: studyQuery.interpretingPhysicianId ?? "",
      organizationId: studyQuery.organizationId ?? "",
      orderingPhysicianId: studyQuery.orderingPhysicianId ?? "",
    }
    // remove keys from queryParams that are empty strings
    Object.keys(queryParams).forEach(
      // @ts-ignore
      (key) => queryParams[key] === "" && delete queryParams[key]
    )
    setSearchParams(queryParams)
  }, [studyQuery])

  const searchStudies = async (
    paginationModel: GridPaginationModel | undefined = undefined
  ) => {
    const filteredQuery = removeEmptyValuesFromObject(studyQuery)

    setIsLoading(true)
    if (paginationModel === undefined) {
      await studies.listStudyOrders(filteredQuery)
    } else {
      await studies.listStudyOrders({
        ...filteredQuery,
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
      })
    }
    setIsLoading(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault()
      event.stopPropagation()
      searchStudies()
    }
  }

  const commonColProps = [
    createBasicGridColDef("id", "UUID", 0),
    createBasicGridColDef("readableId", "Study #", 5),
    createBasicGridColDef("name", "Patient Name", 10),
    createBasicGridColDef("orderingPhysician", "Ord. Physician", 10),
    {
      field: "device",
      headerName: "Device",
      flex: 8,
      editable: false,
      renderCell: (
        // eslint-disable-next-line
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        if (isAdmin) {
          return params.row.assignedDevice
        } else {
          return params.row.device
        }
      },
    },
    {
      field: "date",
      type: "date",
      headerName: "Study Date",
      flex: 8,
      editable: false,
      renderCell: (
        // eslint-disable-next-line
        params: GridRenderCellParams<any, any, Date>
      ) => formatDate(params.row.date),
    },
  ]

  const columns: GridColDef[] = [
    createBasicGridColDef("mrn", "MRN"),
    {
      field: "dob",
      type: "date",
      headerName: "DOB",
      flex: 8,
      editable: false,
      renderCell: (
        // eslint-disable-next-line
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => formatDate(params.row.date),
    },
    createBasicGridColDef("referringPhysician", "Ref. Physician", 10),
    createBasicGridColDef("interpretingPhysician", "Int. Physician", 10),
    {
      field: "status",
      headerName: "Status",
      type: "singleSelect",
      flex: 12,
      editable: false,
      valueOptions: studyStatusValue,
      renderCell: (params) =>
        StudyStatusIndicator(params.row.status as StudyStatus),
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      editable: false,
      renderCell: (params) => {
        const currentRow = params.row

        const analyzeStudy = (e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          e.stopPropagation()
          setDisableGraph(
            user?.primaryRole !== "Physician" ||
              currentRow.status === StudyStatus.COMPLETE
          )
          nav("/dataset/" + currentRow.activeStudyId)
        }

        if (canAnalyzeStudyStatuses.includes(currentRow.status)) {
          return (
            <Button size="sm" onClick={analyzeStudy}>
              Review
            </Button>
          )
        }
      },
    },
  ]

  const openDeleteModal = (studyId: string) => {
    setStudyToBeDeleted(studyId)
    setDeleteModalOpenState(true)
  }

  const openFulfillModal = (studyId: string) => {
    setStudyToBeFulfilled(studyId)
    setFulfillModalOpenState(true)
  }

  const openEditModal = (row: StudyTableRow) => {
    setSelectedPatient({
      name: row.name ?? "",
      uuid: row.patientId ?? "",
    })
    setEditModalData({
      uuid: row.id ?? "",
      patientId: row.patientId,
      address1: row.address1,
      address2: row.address2,
      city: row.city,
      state: row.state,
      zip: row.zip,
      scheduledDate: row.scheduledDate,
      oxygenDesatThreshold: row.oxygenDesatThreshold,
      orderingPhysicianId: row.orderingPhysicianId,
      interpretingPhysicianId: row.interpretingPhysicianId,
      assignedDeviceSerialNumber: row.assignedDevice,
      notes: row.notes,
      type: row.type,
      status: row.modalStatus,
    })
    setOpen(true)
  }

  const cols = props.showAdminOptions
    ? [
        ...commonColProps,
        {
          field: "organization",
          headerName: "Organization",
          flex: 12,
          editable: false,
        },
        {
          field: "status",
          headerName: "Status",
          type: "singleSelect",
          flex: 12,
          editable: false,
          valueOptions: studyStatusValue,
        },
        {
          field: "action",
          headerName: "Action",
          sortable: false,
          editable: false,
          renderCell: (
            // eslint-disable-next-line
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
          ) => {
            const currentRow = params.row
            const uploadStudy = () => {
              setStudyToBeUploaded(currentRow.id)
              setOpenUploadModal(true)
            }
            const analyzeStudy = () => {
              nav("/dataset/" + currentRow.activeStudyId)
            }

            const fulfillStudy = () => {
              openFulfillModal(currentRow.id)
            }

            const rejectStudy = () => {
              studies.rejectStudyInReview(currentRow.id)
            }

            const approveStudy = () => {
              studies.approveStudyForRelease(currentRow.id)
            }

            const downloadEdfFile = () => {
              studies.downloadEdf({ studyId: currentRow.activeStudyId })
            }

            const reprocessStudy = () => {
              studies.reprocessStudy({ studyId: currentRow.activeStudyId })
            }

            const editStudy = () => {
              openEditModal(currentRow)
            }

            const deleteStudy = () => {
              openDeleteModal(currentRow.id)
            }
            const status = currentRow.status
            return (
              <Dropdown>
                <MenuButton
                  slots={{ root: IconButton }}
                  slotProps={{
                    root: { variant: "outlined", color: "neutral" },
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <MoreVert />
                </MenuButton>
                <Menu>
                  <MenuItem onClick={editStudy}>Edit</MenuItem>
                  <MenuItem
                    disabled={status !== StudyStatus.PROCESSED}
                    onClick={approveStudy}
                  >
                    Approve
                  </MenuItem>
                  <MenuItem
                    disabled={
                      status !== StudyStatus.PROCESSED &&
                      status !== StudyStatus.READY_FOR_INTERPRETATION &&
                      status !== StudyStatus.READY_FOR_REVIEW &&
                      status !== StudyStatus.REJECTED &&
                      status !== StudyStatus.COMPLETE
                    }
                    onClick={analyzeStudy}
                  >
                    Review
                  </MenuItem>
                  <MenuItem
                    disabled={
                      ![
                        StudyStatus.PROCESSED,
                        StudyStatus.READY_FOR_REVIEW,
                        StudyStatus.READY_FOR_INTERPRETATION,
                        StudyStatus.COMPLETE,
                        StudyStatus.ABORTED,
                        StudyStatus.REJECTED,
                      ].includes(status)
                    }
                    onClick={downloadEdfFile}
                  >
                    Download EDF
                  </MenuItem>
                  <MenuItem
                    disabled={!currentRow.activeStudyId}
                    onClick={reprocessStudy}
                  >
                    Reprocess Study
                  </MenuItem>
                  <MenuItem
                    disabled={
                      status === StudyStatus.IN_PROGRESS &&
                      status === StudyStatus.AWAITING_UPLOAD
                    }
                    onClick={uploadStudy}
                  >
                    Upload
                  </MenuItem>
                  <MenuItem
                    disabled={status !== StudyStatus.ORDERED}
                    onClick={fulfillStudy}
                  >
                    Fulfill
                  </MenuItem>
                  <MenuItem
                    disabled={status !== StudyStatus.PROCESSED}
                    onClick={rejectStudy}
                  >
                    Reject
                  </MenuItem>
                  <ListDivider />
                  <MenuItem color="danger" onClick={deleteStudy}>
                    Delete
                  </MenuItem>
                </Menu>
              </Dropdown>
            )
          },
        },
      ]
    : [...commonColProps].concat(columns)

  return (
    <>
      <EditStudyModal
        open={open}
        setOpen={setOpen}
        patientData={selectedPatient}
        studyData={editModalData}
      />
      <DeleteStudyModal />
      <FulfillStudyModal />
      <StudyUploadModal />
      <Stack spacing={2} sx={{ overflow: "hidden" }}>
        <Stack
          direction="row"
          display={"flex"}
          justifyContent={"space-between"}
          spacing={2}
        >
          <Stack
            spacing={2}
            display="flex"
            alignItems="center"
            direction={{ xs: "column", sm: "row" }}
          >
            {isUserPhysician ? (
              <FormControl sx={{ width: 120 }}>
                <Switch
                  startDecorator={
                    <Typography level="body-sm">My Studies</Typography>
                  }
                  checked={selfInterpretingPhysician}
                  onChange={(e) => {
                    setStudyQuery({
                      ...studyQuery,
                      interpretingPhysicianId: e.target.checked
                        ? user?.uuid
                        : "",
                      interpretingPhysicianName: e.target.checked
                        ? `${user?.firstName} ${user?.lastName}`
                        : "",
                    })
                    setSelfInterpretingPhysician(e.target.checked)
                    studies.listStudyOrders(studyQuery)
                    //wait for the state to update then search
                  }}
                />
              </FormControl>
            ) : null}
            <FormControl sx={{ width: 120 }}>
              <Input
                size="sm"
                placeholder="Patient name"
                value={studyQuery.patientName}
                onChange={(e) => {
                  setStudyQuery({
                    ...studyQuery,
                    patientName: e.target.value,
                  })
                }}
                onKeyDown={handleKeyDown}
              />
            </FormControl>
            <FormControl sx={{ width: 100 }}>
              <Input
                size="sm"
                placeholder="MRN"
                value={studyQuery.mrn}
                onChange={(e) => {
                  setStudyQuery({
                    ...studyQuery,
                    mrn: e.target.value,
                  })
                }}
                onKeyDown={handleKeyDown}
              />
            </FormControl>
            <FormControl>
              <Input
                size="sm"
                placeholder="Interpreting physician"
                value={studyQuery.interpretingPhysicianName}
                disabled={selfInterpretingPhysician}
                onChange={(e) => {
                  setStudyQuery({
                    ...studyQuery,
                    interpretingPhysicianName: e.target.value,
                  })
                  setSearchParams({})
                }}
                onKeyDown={handleKeyDown}
              />
            </FormControl>
            <FormControl sx={{ width: 150 }}>
              <Input
                size="sm"
                placeholder="Referring physician"
                value={studyQuery.referringPhysicianName}
                onChange={(e) => {
                  setStudyQuery({
                    ...studyQuery,
                    referringPhysicianName: e.target.value,
                  })
                }}
                onKeyDown={handleKeyDown}
              />
            </FormControl>
            {isAdmin && (
              <FormControl sx={{ width: 150 }}>
                <Select
                  size="sm"
                  startDecorator="Organization"
                  value={studyQuery.organizationId}
                  onChange={(_, newValue) => {
                    setStudyQuery({
                      ...studyQuery,
                      organizationId: newValue || undefined,
                    })
                  }}
                  placeholder="All"
                >
                  <Option key={0} value={""}>
                    All
                  </Option>
                  {organizations.map((organization, index) => (
                    <Option key={index + 1} value={organization.uuid}>
                      {organization.name}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl sx={{ minWidth: 150 }}>
              <Select
                size="sm"
                startDecorator="Status"
                multiple
                value={statusFilter}
                onChange={(_, newValue) => {
                  const studyStatuses = newValue.map(
                    (status) => StatusTextToStr[status]
                  )
                  setStudyQuery({
                    ...studyQuery,
                    studyStatuses,
                  })
                  setStatusFilter(newValue)
                }}
                placeholder="All"
                renderValue={(value) => {
                  return (
                    <Sheet sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {value.map((selectedOption) => (
                        <Chip
                          key={selectedOption.value}
                          sx={{
                            borderRadius: "4px",
                          }}
                          variant="outlined"
                        >
                          {selectedOption.label}
                        </Chip>
                      ))}
                    </Sheet>
                  )
                }}
              >
                {Object.values(
                  props.showAdminOptions ? StudyStatus : SimplifiedStudyStatus
                ).map((status, index) => (
                  <Option key={index} value={status}>
                    {status}
                  </Option>
                ))}
              </Select>
            </FormControl>
            <IconButton
              size="sm"
              onClick={() => searchStudies()}
              variant="solid"
              color="primary"
            >
              <Search />
            </IconButton>
            <IconButton
              size="sm"
              onClick={() => {
                setStudyQuery(emptyStudyQuery(user))
                setSelfInterpretingPhysician(isUserPhysician)
                setStatusFilter([])
                studies.listStudyOrders(emptyStudyQuery(user))
              }}
              variant="outlined"
              color="primary"
            >
              <Refresh />
            </IconButton>
          </Stack>
          <NewStudyModal />
        </Stack>
        <Box sx={{ height: "calc(100% - 52px)" }}>
          <StyledDataGrid
            rows={tableRows}
            columns={cols}
            rowCount={studyCount as number}
            loading={isLoading}
            paginationMode="server"
            hideFooterSelectedRowCount={true}
            disableRowSelectionOnClick={true}
            onRowClick={(params, e) => {
              e.preventDefault()
              e.stopPropagation()
              const { row } = params
              nav("/patients/" + row.patientId)
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "date", sort: "desc" }],
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
          />
        </Box>
      </Stack>
    </>
  )
}

export default StudyTable
