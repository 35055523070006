import { eventTypes } from "../const/event.const"
import {
  BeatsEvent,
  CsdRegionsOfInterest,
  DesatEvent,
  eventDataMaxMin,
  FinalAhiEvent,
  LeadOffEvent,
  RespiratoryEvent,
  RhythmEvent,
  SleepEvent,
} from "../interfaces/events.interface"
import { ScaleLinear } from "d3-scale"

export type EventApi =
  | CsdRegionsOfInterest
  | DesatEvent
  | FinalAhiEvent
  | RespiratoryEvent
  | SleepEvent
  | BeatsEvent
  | LeadOffEvent
  | RhythmEvent

export type ScorerEventData = {
  id: number
  event_ts: number[]
  autogenerated?: boolean
  addedBy?: string | null
  addedOn?: number | null
  removed?: boolean
  removedBy?: string | undefined | null
  removedOn?: number | null
  event_data?: eventDataMaxMin
  plot: EventPlot
  type: EventType
  studyID: string
  label?: string
  scoringCriteria?: ScoringCriteria
}

export type EcgAnnotations = ScorerEventData & {
  event_data: { label: string }
  label?: string
  ts: number
  startTS: number
  endTS: number
}

export type SelectedEvent = {
  eventData: ScorerEventData
  mouseEvent: React.MouseEvent<SVGGElement, MouseEvent>
}

export type EventPlot =
  | "HR"
  | "SpO2"
  | "ECG"
  | "Position"
  | "Actigraphy"
  | "Snoring"
  | "Resp"
  | "SANSASleepStage"
  | "Chest"

export type EventType = (typeof eventTypes)[number]

export type ValueOf<T> = T[keyof T]

// Start types and type maps used in multiple components and hooks

export type Range = { start: number; width: number }

export type EditModalPatientData = {
  uuid: string
  firstName: string
  middleName?: string
  lastName: string
  primaryPhone: string
  secondaryPhone?: string
  email: string
  dob: Date
  mrn: string
  race: string
  sex: string
  organizationId: string
}

export type EditModalStudyData = {
  uuid: string
  patientId: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
  scheduledDate: Date
  oxygenDesatThreshold: string
  orderingPhysicianId: string
  interpretingPhysicianId: string
  assignedDeviceSerialNumber: string
  notes: string
  type: string
  status: string
}

export type AnnotatedEventParams = {
  eventID: number
  height: number
  timeScale: ScaleLinear<number, number, never>
}

export type UseEventContextMenuParams = {
  plotsRef: React.RefObject<HTMLDivElement>
}

export type EventSource = "Patient-Triggered" | "Auto-scored" | string

//TODO move this out
export enum ScoringCriteria {
  THREE = 3,
  FOUR = 4,
}

export type YCategoryMap = { [yValue: number]: string }

export type Visibility = "visible" | "hidden" | undefined

export type RefineEventType = EventType | "All"

export enum DetectionFlags {
  MOTION = 1, // 2^0
  LOW_AMP = 2, // 2^1
  LOW_SQI = 4, // 2^2
  BEAT_NOT_FOUND = 8, // 2^3
  XCORR_BELOW_80 = 16, // 2^4
  XCORR_BELOW_90 = 32, // 2^5
}
