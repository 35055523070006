import { useResetRecoilState } from "recoil"
import {
  activeStudyID,
  currentStudy,
  fullNightInterval,
  intervalTimeEventTypeAtom,
  studyMetaData3,
  studyMetaData4,
  studyPlotOrder,
  timeIntervals,
} from "../state/study.state"
import {
  csdRegionsOfInterest3,
  csdRegionsOfInterest4,
  eventIDs,
  eventMetaData3,
  eventMetaData4,
  respiratoryEvents3,
  respiratoryEvents4,
  sleepWakeEvents3,
  sleepWakeEvents4,
} from "../state/event.state"
import {
  ecgBadExclusions,
  ecgExclusions3,
  ecgExclusions4,
  ppgExclusions3,
  ppgExclusions4,
} from "../state/exclusions.state"
import { currentEpoch } from "../state/epoch.state"
import { snackAlert } from "../components/SnackAlerts"
import { allPlotTimeDomain, studySignals } from "../state/signals.state"
import { summaryIndices3, summaryIndices4 } from "../state/graphSummary.state"
import { disableGraph } from "../state/graph.state"
import { reportStatus, isGeneratingPDFReport } from "../state/pdfReport.state"

const useResetRecoilStateData = () => {
  const resetActiveStudyId = useResetRecoilState(activeStudyID)
  const resetStudyMetrics3 = useResetRecoilState(studyMetaData3)
  const resetStudyMetrics4 = useResetRecoilState(studyMetaData4)
  const resetEventIds = useResetRecoilState(eventIDs)
  const resetEcgExclusions3 = useResetRecoilState(ecgExclusions3)
  const resetEcgExclusions4 = useResetRecoilState(ecgExclusions4)
  const resetPpgExclusions3 = useResetRecoilState(ppgExclusions3)
  const resetPpgExclusions4 = useResetRecoilState(ppgExclusions4)
  const resetCurrentEpoch = useResetRecoilState(currentEpoch)
  const resetSnackAlertMsg = useResetRecoilState(snackAlert)
  const resetFullNightIntervalValue = useResetRecoilState(fullNightInterval)
  const resetSelectedTimeInterval = useResetRecoilState(
    intervalTimeEventTypeAtom
  )
  const resetTimeIntervalList = useResetRecoilState(timeIntervals)
  const resetStudyData = useResetRecoilState(studySignals)
  const resetSleepEventData3 = useResetRecoilState(sleepWakeEvents3)
  const resetSleepEventData4 = useResetRecoilState(sleepWakeEvents4)
  const resetSnackAletMsg = useResetRecoilState(snackAlert)
  const resetCurrentStudy = useResetRecoilState(currentStudy)
  const resetEventMetaData3 = useResetRecoilState(eventMetaData3)
  const resetEventMetaData4 = useResetRecoilState(eventMetaData4)
  const resetSummaryIndicies3 = useResetRecoilState(summaryIndices3)
  const resetSummaryIndicies4 = useResetRecoilState(summaryIndices4)
  const resetCsdRegionsOfInterest3 = useResetRecoilState(csdRegionsOfInterest3)
  const resetCsdRegionsOfInterest4 = useResetRecoilState(csdRegionsOfInterest4)
  const resetRespiratoryEvents3 = useResetRecoilState(respiratoryEvents3)
  const resetRespiratoryEvents4 = useResetRecoilState(respiratoryEvents4)
  const resetEcgBadExclusions = useResetRecoilState(ecgBadExclusions)
  const resetReportStatus = useResetRecoilState(reportStatus)
  const resetGeneratingReportPdfStatus = useResetRecoilState(
    isGeneratingPDFReport
  )
  const resetAllPlotTimeDomain = useResetRecoilState(allPlotTimeDomain)
  const resetStudyPlotOrder = useResetRecoilState(studyPlotOrder)
  const resetDisableGraph = useResetRecoilState(disableGraph)
  const resetGraphData = async () => {
    await resetActiveStudyId()
    await resetStudyMetrics3()
    await resetStudyMetrics4()
    await resetEcgExclusions3()
    await resetEcgExclusions4()
    await resetPpgExclusions3()
    await resetPpgExclusions4()
    await resetEventIds()
    await resetCurrentEpoch()
    await resetSnackAlertMsg()
    await resetFullNightIntervalValue()
    await resetSelectedTimeInterval()
    await resetTimeIntervalList()
    await resetStudyData()
    await resetSleepEventData3()
    await resetSleepEventData4()
    await resetSnackAletMsg()
    await resetCurrentStudy()
    await resetEventMetaData3()
    await resetEventMetaData4()
    await resetSummaryIndicies3()
    await resetSummaryIndicies4()
    await resetCsdRegionsOfInterest3()
    await resetCsdRegionsOfInterest4()
    await resetRespiratoryEvents3()
    await resetRespiratoryEvents4()
    await resetEcgBadExclusions()
    await resetReportStatus()
    await resetGeneratingReportPdfStatus()
    await resetAllPlotTimeDomain()
    await resetStudyPlotOrder()
    await resetDisableGraph()
  }

  return {
    resetGraphData,
  }
}

export default useResetRecoilStateData
