import { DragMoveCallback } from "@huxley-medical/react-components/hooks/mouse"
import { ScrollRoutineContext } from "../../../../types/scroller.type"
import { eventIntersector } from "../../event/utils"
import { binarySearchData, findMaxMinValues } from "../../../../utils/utils"
import { EventPlot } from "../../../../types/event.type"
import { defaultPlotEventTypeMap } from "../../../../const/event.const"
import { ExclusionAnnotation } from "../../../../types/exclusion.type"
import { HandleCreateEventParams } from "../../../../types/handlers.type"

/**
 * handleCreateEvent creates a new event and saves to recoil state if the
 * drawn range is valid.
 *
 * @param {HandleCreateEventParams} handleCreateEventParams
 * @returns {DragMoveCallback} dragMoveCallback
 */

const handleCreateEvent =
  ({
    plot,
    width,
    allPlotEvents,
    hrExclusionData,
    studyID,
    plotData,
    scrollRoutine,
    currentUserData,
    endDraw,
    createEvent,
    selectedScoringCriteria,
  }: HandleCreateEventParams): DragMoveCallback =>
  async () => {
    const ctx = scrollRoutine.current.additional as ScrollRoutineContext

    if (ctx.rangePx === undefined) return

    const startPx = ctx.rangePx[0]
    const widthPx = ctx.rangePx[1]
    let start = startPx
    let end = startPx + widthPx
    const timeWidth = ctx.timeScale.invert(end) - ctx.timeScale.invert(start)

    const eventIntersectionDetector = eventIntersector({
      widthPx,
      parentWidth: width,
      exclusions: ctx.exclusionData,
      allPlotEvents,
      timeScale: ctx.timeScale,
    })

    const { suggestedRange, intersectedWith } = eventIntersectionDetector(
      start,
      end
    )

    // complete overlap or suggestion overlap, do nothing
    if (suggestedRange === undefined && intersectedWith === undefined) {
      endDraw()
      return
    }

    // there was an intersection, set new draw points
    if (intersectedWith !== undefined && suggestedRange !== undefined) {
      // TODO: we may want to subtract the intersection from total width
      // for repositioning
      start = suggestedRange[0]
      end = suggestedRange[1]
    }

    const minTimeWidth = 2000 // 2seconds
    // Ensure minimum width of event
    if (plot === "SpO2" ? timeWidth > minTimeWidth : true) {
      let startingInterval,
        endingInterval,
        max: number | null,
        min: number | null

      if (plot === "SpO2") {
        startingInterval = binarySearchData(
          plotData,
          ctx.timeScale.invert(start)
        )
        endingInterval = binarySearchData(plotData, ctx.timeScale.invert(end))

        const maxMinData = findMaxMinValues(
          plotData,
          startingInterval,
          endingInterval
        )

        max = maxMinData.max
        min = maxMinData.min

        const plotHR = "HR" as EventPlot

        await createEvent({
          id: new Date().getTime(),
          plot,
          studyID,
          addedBy: currentUserData?.uuid,
          addedOn: new Date().getTime() / 1000,
          removed: false,
          removedBy: null,
          removedOn: null,
          type: defaultPlotEventTypeMap[plot],
          event_ts: [ctx.timeScale.invert(start), ctx.timeScale.invert(end)],
          event_data: {
            max_spo2: max,
            min_spo2: min,
          },
          autogenerated: false,
          scoringCriteria: selectedScoringCriteria,
        })

        if (
          !hrExclusionData?.some(
            (eD: ExclusionAnnotation) =>
              ctx.timeScale.invert(end) >= eD.startTS * 1000 &&
              ctx.timeScale.invert(end) <= eD.endTS * 1000
          )
        ) {
          await createEvent({
            id: new Date().getTime() + 350,
            plot: plotHR,
            studyID,
            addedBy: currentUserData?.uuid,
            addedOn: new Date().getTime() / 1000,
            removed: false,
            removedBy: null,
            removedOn: null,
            autogenerated: false,
            type: defaultPlotEventTypeMap["HR"],
            event_ts: [
              ctx.timeScale.invert(end),
              ctx.timeScale.invert(end) + 3000,
            ],
            scoringCriteria: selectedScoringCriteria,
          })
        }
      } else {
        await createEvent({
          id: new Date().getTime(),
          plot,
          studyID,
          addedBy: currentUserData?.uuid,
          addedOn: new Date().getTime() / 1000,
          removed: false,
          removedBy: null,
          removedOn: null,
          autogenerated: false,
          type: defaultPlotEventTypeMap[plot],
          event_ts: [ctx.timeScale.invert(end), ctx.timeScale.invert(end)],
          scoringCriteria: selectedScoringCriteria,
        })
      }
    }
    endDraw()
  }

export default handleCreateEvent
