import { md5 as md5sum } from "js-md5"
import { snackAlert } from "../components/SnackAlerts"
import { usePortalApi } from "../connections"
import { uploadPartSize } from "../constants"
import {
  InterpretStudyIn,
  SleepStudyIn,
  SleepStudyOrderIn,
  SleepStudyOrderOut,
} from "../generated/fetchclient/models"
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil"
import { studies, studyCount } from "../state/study.state"
import { handleApiError } from "../utils/apiUtils"
import {
  CreateSleepStudyUrlRequest,
  GetAllStudyOrdersRequest,
  GetSleepStudyUrlRequest,
  GetStudyEdfRequest,
  ReprocessStudyRequest,
} from "../generated/fetchclient"
import { removeEmptyValuesFromObject } from "../utils/utils"
import { pdfUrl } from "../state/pdf.state"

interface UploadPart {
  ETag: string
  PartNumber: number
}

function convertToBase64(hexstring: string) {
  const arrOfHex = hexstring.match(/\w{2}/g)
  if (arrOfHex == null) {
    // Handle the case when hexstring is not in proper format
    return ""
  }
  return btoa(
    arrOfHex
      .map(function (a) {
        return String.fromCharCode(parseInt(a, 16))
      })
      .join("")
  )
}

/**
 * Updates a study in the list of studies.
 *
 * @param studies The list of studies.
 * @param study The study to update.
 */
function updateStudyInList(
  studies: SleepStudyOrderOut[],
  study: SleepStudyOrderOut
) {
  return studies.map((s) => {
    if (s.uuid === study.uuid) {
      return study
    }
    return s
  })
}

const useStudies = () => {
  const api = usePortalApi()
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const setApiStudies = useSetRecoilState(studies)
  const setApiStudyCount = useSetRecoilState(studyCount)
  const resetStudies = useResetRecoilState(studies)
  const resetStudyCount = useResetRecoilState(studyCount)
  const pdfUrlLink = useRecoilValue(pdfUrl)

  /**
   * Fulfills a study by updating its order with the assigned device serial number and status.
   *
   * @param studyId The ID of the study to be fulfilled.
   * @param deviceId The serial number of the device assigned to the study.
   */
  const fulfillStudy = async (studyId: string, deviceId: string) => {
    if (api === undefined) return

    try {
      const order = await api.updateStudyOrder({
        studyOrderId: studyId,
        sleepStudyOrderUpdate: {
          assignedDeviceSerialNumber: deviceId,
          status: "IP",
        },
      })
      setApiStudies((studies) => updateStudyInList(studies, order))
      setSnackAlertMsg({
        open: true,
        message: "Study Fulfilled",
        severity: "success",
        autoHideDuration: 5000,
      })
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
      throw error
    }
  }

  /**
   * Creates a study file to the server.
   *
   * @param studyInput Study data to be added.
   */

  const createStudy = async (study: SleepStudyOrderIn) => {
    if (api === undefined) return

    try {
      await api.createStudyOrder({
        sleepStudyOrderIn: study,
      })
      setSnackAlertMsg({
        open: true,
        message: "Study Created",
        severity: "success",
        autoHideDuration: 5000,
      })
      listStudyOrders()
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
      return
    }
  }

  /**
   * Uploads a study file to the server.
   *
   * @param studyId The ID of the study.
   * @param file The file to be uploaded.
   */
  const uploadStudy = async (studyId: string, file: File) => {
    if (api === undefined) return

    try {
      const numParts = Math.ceil(file.size / uploadPartSize)
      const fileParts: Blob[] = []
      const filePartsMd5: string[] = await Promise.all(
        Array(numParts)
          .fill(0)
          .map(async (_, index) => {
            const slice = file.slice(
              index * uploadPartSize,
              (index + 1) * uploadPartSize
            )
            fileParts.push(slice)

            const reader = new FileReader()
            return new Promise((resolve, _) => {
              reader.onload = function (event) {
                const buff = event.target?.result as ArrayBuffer
                const mymd5 = md5sum(buff)
                const b64md5 = convertToBase64(mymd5)
                resolve(b64md5)
              }
              reader.readAsArrayBuffer(slice)
            })
          })
      )

      const { key, urls, uploadId } = await api.uploadStudy({
        studyOrderId: studyId,
        requestUploadIn: { md5Hashes: filePartsMd5 },
      })

      const parts: UploadPart[] = []
      await Promise.all(
        urls.map(async (url, index) => {
          const partNum = index + 1
          const options: RequestInit = {
            method: "PUT",
            body: fileParts[index],
            headers: {
              "Content-MD5": filePartsMd5[index],
            },
          }
          const response = await fetch(url, options)
          parts.push({
            ETag: response.headers.get("ETag") || "",
            PartNumber: partNum,
          })
        })
      )
      parts.sort((a, b) => a.PartNumber - b.PartNumber)

      await api.completeStudyUpload({
        completeUploadIn: {
          objectKey: key,
          parts,
          uploadId,
        },
      })
      setSnackAlertMsg({
        open: true,
        message: "Study Upload Complete",
        severity: "success",
        autoHideDuration: 5000,
      })
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
      throw error
    }
  }

  /**
   * Approves a study for release.
   *
   * @param studyId The ID of the study to be approved.
   */
  const approveStudyForRelease = async (studyId: string) => {
    if (api === undefined) return

    try {
      const order = await api.updateStudyOrder({
        studyOrderId: studyId,
        sleepStudyOrderUpdate: {
          status: "RR", // RR = Ready for Review in backend
        },
      })
      setApiStudies((studies) => updateStudyInList(studies, order))
      setSnackAlertMsg({
        open: true,
        message: "Study Approved",
        severity: "success",
        autoHideDuration: 5000,
      })
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Rejects a study that is in review.
   *
   * @param studyId The ID of the study to be rejected.
   */
  const rejectStudyInReview = async (studyId: string) => {
    if (api === undefined) return

    try {
      const order = await api.updateStudyOrder({
        studyOrderId: studyId,
        sleepStudyOrderUpdate: {
          status: "OD", // OD = Ordered in backend
        },
      })
      setApiStudies((studies) => updateStudyInList(studies, order))
      setSnackAlertMsg({
        open: true,
        message: "Study Rejected",
        severity: "success",
        autoHideDuration: 5000,
      })
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Deletes a study by its ID.
   *
   * @param studyId The ID of the study to delete.
   */
  const deleteStudy = async (studyId: string) => {
    if (api === undefined) return

    try {
      await api.deleteStudyOrder({ studyOrderId: studyId })
      setApiStudies((studies) =>
        studies.filter((study) => study.uuid !== studyId)
      )
      setSnackAlertMsg({
        open: true,
        message: "Study Deleted",
        severity: "success",
        autoHideDuration: 5000,
      })
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }
  /**
   * Updates a study events.
   *
   * @param studyId The ID of the study to be updated.
   * @param eventData The updated event data object.
   */

  const updateStudyEvents = async (
    studyId: string,
    eventData: SleepStudyIn
  ) => {
    if (api === undefined) return

    try {
      await api.updateStudy({
        studyId: studyId,
        sleepStudyIn: eventData,
      })
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Updates a study Order.
   *
   * @param studyId The ID of the study to be updated.
   * @param study The updated study object.
   */
  const updateStudyOrder = async (
    studyId: string,
    study: SleepStudyOrderIn
  ) => {
    if (api === undefined) return

    try {
      const order = await api.updateStudyOrder({
        studyOrderId: studyId,
        sleepStudyOrderUpdate: study,
      })
      setApiStudies((studies) => updateStudyInList(studies, order))
      setSnackAlertMsg({
        open: true,
        message: "Study Updated",
        severity: "success",
        autoHideDuration: 5000,
      })
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Lists studies.
   *
   * @param query The query to filter the list of studies.
   */
  const listStudyOrders = async (query: GetAllStudyOrdersRequest = {}) => {
    if (api === undefined) return

    try {
      const filteredQuery = removeEmptyValuesFromObject(query)
      const studies = await api.getAllStudyOrders(filteredQuery)
      setApiStudies(studies.items)
      setApiStudyCount(studies.count)
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Interprets a study.
   *
   * @param studyId The ID of the study to be interpreted.
   * @param eventData The data to be used to interpret the study.
   */
  const interpretStudy = async (
    studyId: string,
    eventData: InterpretStudyIn
  ) => {
    if (api === undefined) return false

    try {
      const order = await api.interpretStudy({
        studyId: studyId,
        interpretStudyIn: eventData,
      })
      setApiStudies((studies) => updateStudyInList(studies, order))
      setSnackAlertMsg({
        open: true,
        message: "Study Interpreted",
        severity: "success",
        autoHideDuration: 5000,
      })
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
      return false
    }
    return true
  }

  /**
   *
   * @param param0
   * @returns
   */

  const downloadEdf = async ({ studyId }: GetStudyEdfRequest) => {
    if (api === undefined) return false

    try {
      const { presignedUrl } = await api.getStudyEdf({ studyId })
      if (presignedUrl === undefined) {
        throw new Error("Edf file not available")
      }
      const response = await fetch(presignedUrl)
      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`)
      }
      const blob = await response.blob()
      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.download = `${studyId}.EDF`
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(link.href)
      link.remove()
      return true
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
      return false
    }
  }

  const createSleepStudyReportUrl = async ({
    studyId,
  }: CreateSleepStudyUrlRequest) => {
    if (api === undefined) return false
    try {
      const uploadSleepReportStudyUrl = await api.createSleepStudyUrl({
        studyId,
      })
      if (
        !uploadSleepReportStudyUrl ||
        !uploadSleepReportStudyUrl.presignedUrl ||
        !uploadSleepReportStudyUrl.fields
      ) {
        throw new Error("Presigned URL or fields are not available.")
        return
      }
      const formData = new FormData()
      Object.entries(uploadSleepReportStudyUrl.fields).forEach(
        ([key, value]) => {
          formData.append(key, value)
        }
      )

      const pdfReportResponse = await fetch(pdfUrlLink)
      const blob = await pdfReportResponse.blob()
      formData.append("file", blob, "document.pdf")
      const uploadResponse = await fetch(
        uploadSleepReportStudyUrl.presignedUrl,
        {
          method: "POST",
          body: formData,
        }
      )

      if (uploadResponse.ok) {
        return true
      } else {
        throw new Error(
          "Unable to upload file currently. Please try after some time"
        )
      }
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
      return false
    }
  }

  const downloadSleepStudy = async ({ studyId }: GetSleepStudyUrlRequest) => {
    if (api === undefined) return false

    try {
      const { presignedUrl } = await api.getSleepStudyUrl({ studyId })
      if (presignedUrl === undefined) {
        throw new Error("Report not available")
      }
      const response = await fetch(presignedUrl)
      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`)
      }
      const blob = await response.blob()
      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.download = `${studyId}.pdf`
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(link.href)
      link.remove()
      return true
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
      return false
    }
  }

  const reprocessStudy = async ({ studyId }: ReprocessStudyRequest) => {
    if (api === undefined) return false

    try {
      await api.reprocessStudy({ studyId })
      setSnackAlertMsg({
        open: true,
        message: "Reprocessing Study",
        severity: "success",
        autoHideDuration: 5000,
      })
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
      return false
    }
    return true
  }

  const resetStudyData = () => {
    resetStudies()
    resetStudyCount()
  }

  return {
    studies: useRecoilValue(studies),
    studyCount: useRecoilValue(studyCount),
    approveStudyForRelease,
    createStudy,
    deleteStudy,
    listStudyOrders,
    updateStudyEvents,
    fulfillStudy,
    interpretStudy,
    rejectStudyInReview,
    updateStudyOrder,
    uploadStudy,
    downloadEdf,
    downloadSleepStudy,
    resetStudyData,
    reprocessStudy,
    createSleepStudyReportUrl,
  }
}

export default useStudies
