import { StepLinePlot } from "@huxley-medical/react-components/components"
import { timeSeriesPointsToPoints } from "../../../utils/timeUtils"
import { TimeSeriesPoint } from "@huxley-medical/react-components/types"
import { ScaleLinear } from "d3-scale"
import { memo } from "react"

interface GraphStepLinePlotProps {
  data: TimeSeriesPoint[]
  timeScale: ScaleLinear<number, number, never>
  yScale: ScaleLinear<number, number, never>
  plotWidth: number
  lineColor: string
  lineHeight: number
}

const GraphStepLinePlot = ({
  data,
  timeScale,
  yScale,
  plotWidth,
  lineHeight,
  lineColor,
}: GraphStepLinePlotProps) => {
  return (
    <StepLinePlot
      data={timeSeriesPointsToPoints(data)}
      stroke={lineColor}
      strokeWidth={1.5}
      width={plotWidth}
      height={lineHeight}
      xScale={timeScale}
      yScale={yScale}
    />
  )
}

export default memo(GraphStepLinePlot, (_prevProps, _newProps) => {
  return (
    _prevProps.plotWidth === _newProps.plotWidth &&
    _prevProps.lineColor === _newProps.lineColor &&
    _prevProps.timeScale.range().join() ===
      _newProps.timeScale.range().join() &&
    _prevProps.timeScale.domain().join() ===
      _newProps.timeScale.domain().join() &&
    _prevProps.yScale.range().join() === _newProps.yScale.range().join() &&
    _prevProps.yScale.domain().join() === _newProps.yScale.domain().join()
  )
})
