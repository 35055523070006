import { Group } from "@visx/group"
import { scaleLinear } from "@visx/scale"
import { StyledSvg } from "@huxley-medical/react-components/components"
import { max, min } from "d3-array"
import { padDomain } from "@huxley-medical/react-components/utils"
import PlotEvents from "./plotEvents/PlotEvents"
import { TickFormatter } from "@visx/axis"
import { TimeSeriesPoint } from "@huxley-medical/react-components/types"
import PlotExclusions from "./exclusion/PlotExclusions"
import { NumberValue, ScaleLinear } from "d3-scale"
import { EventPlot } from "../../types/event.type"
import { PlotLineType } from "../../types/line.type"
import { Line } from "@visx/shape"
import { styled } from "@mui/joy/styles"
import { Bar } from "@visx/shape"
import OverlayWithTooltip from "./yTooltip/OverlayWithTolltip"
import { PositionMap } from "../../const/signals.const"
import { useMemo, useRef } from "react"
import LinePathPlotNumbers from "./linePathPlotNumbers/LinePathPlotNumbers"
import { numYTicksDefault } from "./StackedPlots"
import GridGraphRows from "./Graph/GridGraphRows"
import GridGraphColumns from "./Graph/GridGraphColumns"
import GraphLinePathPlot from "./Graph/GraphLinePathPlot"
import GraphStepLinePlot from "./Graph/GraphStepLinePlot"
import GraphPlotLeftAxis from "./Graph/GraphPlotLeftAxis"
import GraphPlotLeftAxisLabel from "./Graph/GraphPlotLeftAxisLabel"
import { isNumberValue } from "../../utils/seriesUtils"

export const StyledGraphBackground = styled(Bar)({
  fill: "#FFF",
  stroke: "#000",
  strokeWidth: "0px",
})

type Scale = {
  minY: number
  maxY: number
}

type StackableLinePlotParams = {
  title: string
  lineColor: string
  inx: number
  lineHeight: number
  width: number
  tickIntervals: number
  timeScale: ScaleLinear<number, number, never>
  //yScale: ScaleLinear<number, number, never>
  plotType: EventPlot
  plotWidth: number
  yTicks: number[] | undefined
  yTickFormat?: TickFormatter<NumberValue> | undefined
  data: TimeSeriesPoint[]
  unit: string
  plotLine: PlotLineType
  xPageStart: number
  xPageEnd: number
  yDomainPadding: number | undefined
  yScales: {
    Position: Scale
    SANSASleepStage: Scale
    SpO2: Scale
    HR: Scale
    Actigraphy: Scale
    Chest: Scale
    Resp: Scale
    Snoring: Scale
    ECG: Scale
  }
  handleOpenScaleModal: (plotType: EventPlot) => void
}

export const topPadding = 0
export const bottomPadding = 20
export const labelWidth = 70

export const StyledLine = styled(Line)({
  fill: "transparent",
  stroke: "#000",
  strokeWidth: "1px",
  zIndex: 99999,
})

/**
 * StackableLinePlot is a functional component used to render a stacked line
 * plot contained in the DatasetContent component. It features a PlotEvents
 * overlay which is used to annotate events over the line plot.
 *
 * Grid lines are aligned to the start of the given data, based on
 * epochs (30 seconds) and quarter epochs (7.5 seconds) for the x-axis.
 * And quarters of the y-scale domain.
 *
 * @param {StackableLinePlotParams} stackableLinePlotParams
 * @returns {JSX.Element} JSX.Element
 */
const StackableLinePlot = ({
  title,
  lineColor,
  inx,
  width,
  lineHeight,
  timeScale,
  tickIntervals,
  plotType,
  plotWidth,
  data,
  yTicks,
  yTickFormat,
  yScales,
  unit,
  plotLine,
  yDomainPadding,
  handleOpenScaleModal,
}: StackableLinePlotParams): JSX.Element => {
  // determine y-scale for this plot/data channel

  let yMin: number
  let yMax: number

  //TODO move this out to one of the arguments.
  if (["Position", "SANSASleepStage"].includes(plotType)) {
    yMin = yScales[plotType].minY
    yMax = yScales[plotType].maxY
  } else {
    yMin =
      (isNumberValue(yScales[plotType].minY)
        ? yScales[plotType].minY
        : min(data, (d) => d.value) || 0) - (plotType === "ECG" ? 0.5 : 0)

    yMax = isNumberValue(yScales[plotType].maxY)
      ? yScales[plotType].maxY
      : max(data, (d) => d.value) || 0
  }

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: [yMin, yMax],
        range: [lineHeight, 0],
        nice: numYTicksDefault,
      }),
    [yMin, yMax, lineHeight]
  )
  //}

  // Override domain if padding config is set for the plot
  if (yDomainPadding !== undefined) {
    yScale.domain(padDomain([yMin, yMax], yDomainPadding))
  }

  // Slice off top and bottom ticks so they don't overflow
  // stacked plots without margins
  const defaultyTicks = yScale.ticks(numYTicksDefault).slice(1, -1)

  const yTicksData = yTicks ? yTicks : defaultyTicks

  // Used for relative mouse coord calculations
  const innerRef = useRef<SVGRectElement | null>(null)
  const yTickPositionFormatter = (num: NumberValue) => {
    const positionKey = Object.entries(PositionMap).find(
      ([_, val]) => val === num
    )
    return positionKey ? positionKey[0] : "?"
  }

  const OverlayToolTip = () => {
    return (
      <OverlayWithTooltip
        isDrawDisabled={false}
        height={lineHeight}
        width={width - labelWidth}
        innerRef={innerRef}
        tooltipParams={{
          timeScale,
          height: lineHeight,
          width,
          data,
          unit,
          yTickFormat:
            plotType === "Position" ? yTickPositionFormatter : yTickFormat,
          yScale,
        }}
      />
    )
  }

  return (
    <Group
      className="stackable-line-plot"
      key={plotType}
      top={inx * lineHeight + topPadding}
      left={0}
    >
      <StyledSvg
        x={labelWidth}
        y={0}
        height={lineHeight}
        width={width - labelWidth}
        key={plotType + " " + title}
      >
        <StyledGraphBackground width={width - labelWidth} height={lineHeight} />
        {["SpO2", "ECG", "HR"].includes(plotType) ? (
          <>
            <PlotExclusions
              timeScale={timeScale}
              plot={plotType}
              height={lineHeight}
              width={width - labelWidth}
            >
              <PlotEvents
                timeScale={timeScale}
                plot={plotType}
                height={lineHeight}
                width={width - labelWidth}
                xOffset={labelWidth}
                data={data}
                unit={unit}
                yTickFormat={yTickFormat}
                yScale={yScale}
                key={title + " " + plotType}
                innerRef={innerRef}
              >
                <GridGraphRows
                  yScale={yScale}
                  plotWidth={plotWidth}
                  lineHeight={lineHeight}
                />
                <GridGraphColumns
                  scale={timeScale}
                  width={plotWidth}
                  height={lineHeight}
                  tickIntervals={tickIntervals}
                  stroke="#E2E2E2"
                />
                <GridGraphColumns
                  scale={timeScale}
                  width={plotWidth}
                  height={lineHeight}
                  tickIntervals={tickIntervals}
                  stroke="#000000"
                />
                {plotLine === "line" && (
                  <>
                    <GraphLinePathPlot
                      key={plotType}
                      data={data}
                      plotWidth={plotWidth}
                      lineColor={lineColor}
                      timeScale={timeScale}
                      yScale={yScale}
                    />
                    <LinePathPlotNumbers
                      title={title}
                      timeScale={timeScale}
                      yScale={yScale}
                      textColor="#000"
                      data={data}
                    />
                  </>
                )}
                {plotLine === "step-line" && (
                  <GraphStepLinePlot
                    data={data}
                    plotWidth={plotWidth}
                    lineColor={lineColor}
                    timeScale={timeScale}
                    lineHeight={lineHeight}
                    yScale={yScale}
                  />
                )}
                <OverlayToolTip />
              </PlotEvents>
            </PlotExclusions>
          </>
        ) : (
          <>
            <GridGraphRows
              yScale={yScale}
              plotWidth={plotWidth}
              lineHeight={lineHeight}
            />
            <GridGraphColumns
              scale={timeScale}
              width={plotWidth}
              height={lineHeight}
              tickIntervals={tickIntervals}
              stroke="#E2E2E2"
            />
            <GridGraphColumns
              scale={timeScale}
              width={plotWidth}
              height={lineHeight}
              tickIntervals={tickIntervals}
              stroke="#000000"
            />
            {plotLine === "line" && (
              <>
                <GraphLinePathPlot
                  key={plotType}
                  data={data}
                  plotWidth={plotWidth}
                  lineColor={lineColor}
                  timeScale={timeScale}
                  yScale={yScale}
                />
                <LinePathPlotNumbers
                  key={data.length}
                  title={title}
                  timeScale={timeScale}
                  yScale={yScale}
                  textColor="#000"
                  data={data}
                />
              </>
            )}
            {plotLine === "step-line" && (
              <GraphStepLinePlot
                key={plotType}
                data={data}
                plotWidth={plotWidth}
                lineColor={lineColor}
                timeScale={timeScale}
                lineHeight={lineHeight}
                yScale={yScale}
              />
            )}
            <OverlayToolTip />
          </>
        )}
      </StyledSvg>

      <GraphPlotLeftAxis
        left={labelWidth}
        scale={yScale}
        tickValues={yTicksData}
        tickFormat={yTickFormat}
      />

      <GraphPlotLeftAxisLabel
        labelWidth={labelWidth}
        lineHeight={lineHeight}
        lineColor={lineColor}
        handleOpenScaleModal={handleOpenScaleModal}
        plotType={plotType}
        title={title}
      />

      {inx === 0 && (
        <StyledLine
          className="top-label-border"
          from={{ x: 0, y: 0 }}
          to={{ x: labelWidth, y: 0 }}
        />
      )}
      <StyledLine
        className="left-label-border"
        from={{ x: 1, y: 0 }}
        to={{ x: 1, y: lineHeight }}
      />
      <StyledLine
        className="right-label-border"
        from={{ x: labelWidth, y: 0 }}
        to={{ x: labelWidth, y: lineHeight }}
      />
      <StyledLine
        className="bottom-plot-border"
        from={{ x: 0, y: lineHeight }}
        to={{ x: plotWidth + labelWidth, y: lineHeight }}
      />
      <StyledLine
        className="right-plot-border"
        from={{ x: plotWidth + labelWidth, y: 0 }}
        to={{ x: plotWidth + labelWidth, y: lineHeight }}
      />
    </Group>
  )
}

export default StackableLinePlot
