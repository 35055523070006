/* The following code is adapted from the algorithm Largest triangle three buckets from
the Masters thesis of Sveinn Steinarsson titled Downsamplint Time Series for Visual
Representation.

This algorithm is used to downsample the data points in the time series plots to
a reasonable number of points to render in the browser. The algorithm is also described
in the following blog post:
https://observablehq.com/@d3/downsampling-methods

The original algorithm is implemented in JavaScript here:
https://github.com/sveinn-steinarsson/flot-downsample/blob/master/jquery.flot.downsample.js
*/

import { Point } from "types"

export function lttbDownsample(data: Point[], threshold: number): Point[] {
  const n = data.length

  if (n <= 2 || threshold >= n || threshold === 0) {
    // Not enough points to downsample
    return data
  }

  const sampledData: Point[] = []
  const bucketSize = (n - 2) / (threshold - 2)
  let a = 0 // Index of the leftmost point in the current bucket
  let nextA = 0
  let maxArea
  let area

  // First point is always added
  sampledData.push(data[0])

  for (let i = 1; i < threshold - 2; i += 1) {
    // Step 1: Calculate point average for next bucket (containing c)
    let avgX = 0
    let avgY = 0
    const avgRangeStart = Math.floor((i - 1) * bucketSize) + 1
    let avgRangeEnd = Math.floor(i * bucketSize) + 1
    // make sure the range is within the bounds of the data array
    avgRangeEnd = avgRangeEnd < n ? avgRangeEnd : n
    const avgRangeLength = avgRangeEnd - avgRangeStart

    // Calculate average point in the bucket range
    for (let j = avgRangeStart; j <= avgRangeEnd; j += 1) {
      avgX += data[j].x
      avgY += data[j].y
    }
    avgX /= avgRangeLength
    avgY /= avgRangeLength

    // Step 2: Get the range for this bucket

    let rangeOffs = Math.floor((i + 0) * bucketSize) + 1
    const rangeTo = Math.floor((i + 1) * bucketSize) + 1

    const pointAX = data[a].x
    const pointAY = Number.isNaN(data[a].y) ? 0 : data[a].y

    maxArea = -1
    area = -1

    for (; rangeOffs < rangeTo; rangeOffs += 1) {
      const pointBX = data[rangeOffs].x
      const pointBY = Number.isNaN(data[rangeOffs].y) ? 0 : data[rangeOffs].y
      const pointCX = avgX
      const pointCY = Number.isNaN(avgY) ? 0 : avgY

      area =
        Math.abs(
          (pointAX - pointCX) * (pointBY - pointAX) -
            (pointAX - pointBX) * (pointCY - pointAY)
        ) * 0.5

      if (area > maxArea) {
        maxArea = area
        nextA = rangeOffs // Next a is this b
      }
    }

    sampledData.push(data[nextA]) // Pick this point from the bucket
    a = nextA // Next a is the last b
  }

  // Add the last point
  sampledData.push(data[n - 1])

  return sampledData
}
